import React from "react";
import { Close } from "../../assets/svg/AllSvg";
import { AddedCompany, Registration, Switch } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";

const AddCompany = ({ setAddCompanyPopup }) => {
  const navigate = useNavigate();

  // const handleNewCompany = () => {
  //   setAddCompanyPopup(false);
  //   navigate("/new-company", { state: { companyType: "Incorporate" } });
  // };

  return (
    <>
      <div className="backdrop-blur-sm bg-black/40 flex items-center justify-center w-full fixed top-0 left-0 right-0 z-[999999] mx-auto h-screen">
        <div className="bg-white drop-shadow-lg rounded-lg sm:w-full w-80    max-w-xl min-h-36 py-5 px-6 mx-auto relative">
          <div className="flex justify-center items-center mb-2">
            <img
              src={AddedCompany}
              alt="logo"
              className=" sm:h-24 sm:w-28 h-16 w-16"
            />
            <p className="sm:text-3xl text-lg  font-ebGaramond font-normal w-full ml-6">
              Let’s Get Started Together!
            </p>
          </div>
          <Close
            className="cursor-pointer absolute top-5 right-5"
            onClick={() => {
              setAddCompanyPopup(false);
              sessionStorage.setItem("isCompanyPopup", false);
            }}
          />
          <div className="w-full grid grid-cols-2 gap-2 mb-2">
            <button
              className=" bg-light_secondary_shades sm:h-60 h-32  rounded-lg flex justify-center items-center flex-col"
              onClick={() => {
                setAddCompanyPopup(false);
                navigate("/new-company", {
                  state: { companyType: "incorporate" },
                });
              }}
            >
              <img
                src={Registration}
                alt="logo"
                className="object-cover w-6 h-6"
              />
              <p className="sm:text-xl text-base font-ebGaramond font-normal text-secondary">
                Incorporate now
              </p>
            </button>

            <button
              className=" bg-light_secondary_shades sm:h-60 h-32  rounded-lg flex justify-center items-center flex-col"
              onClick={() => {
                setAddCompanyPopup(false);
                navigate("/new-company", { state: { companyType: "eibiz" } });
              }}
            >
              <img src={Switch} alt="logo" className="object-cover w-6 h-6" />
              <p className="sm:text-xl text-base font-ebGaramond font-normal text-green-600">
                Switch to EI BIZ
              </p>
            </button>
          </div>
          <div className="flex justify-center items-center flex-col">
            <p className=" sm:text-xl text-lg font-ebGaramond font-light">
              Not sure what to do?{" "}
            </p>
            <Link
              to={""}
              className="border-b border-blue-700 text-blue-700 font-normal text-sm font-ebGaramond"
            >
              contact us now!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompany;
