import React from "react";
import ButtonLoader from "./ButtonLoader";

const ConfirmationModel = ({
  onClose,
  confirmText,
  onConfirm,
  isLoading,
  maxWidth,
}) => {
  return (
    <>
      <div className="model" onClick={onClose}>
        <div
          className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2
            className={`sm:text-[34px] text-center sm:leading-tight font-ebGaramond mb-5 ${maxWidth}`}
          >
            Confirm {confirmText}?
          </h2>
          <div className="space-x-2 items-center justify-center flex">
            <button
              className="btn-outline text-xs uppercase h-10 w-28"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn-theme text-xs uppercase text-center h-10 flex justify-center items-center w-28"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading ? <ButtonLoader /> : <span>Confirm</span>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModel;
