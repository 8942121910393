import React, { useState } from "react";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import { useEffect } from "react";
import { Trash } from "../../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";

const LocalDirector = () => {
  const [noOfDirector, setNoOfDirector] = useState(1);
  const [directorInformation, setDirectorInformation] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (noOfDirector) {
      const newDirectors = Array.from({ length: noOfDirector }, () => ({
        full_name: "",
        email: "",
      }));
      setDirectorInformation(newDirectors);
    }
  }, [noOfDirector]);

  // Remove Director Information
  const removeDirectorInformation = (index) => {
    setDirectorInformation([
      ...directorInformation.slice(0, index),
      ...directorInformation.slice(index + 1),
    ]);
  };

  const updateDirectorInformation = (index, full_name, email) => {
    const updatedDirectorInformation = directorInformation?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          full_name: full_name,
          email: email,
        };
      } else {
        return item;
      }
    });
    setDirectorInformation(updatedDirectorInformation);
  };

  const handleEpIncrement = () => {
    setNoOfDirector((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };

  const handleEpDecrement = () => {
    setNoOfDirector((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  const isValid = () => {
    return directorInformation.every(
      (director) => director.full_name.trim() && director.email.trim()
    );
  };

  return (
    <div className="space-y-5">
      <BackButton
        onClick={() => {
          navigate("/service-request");
        }}
      />
      <div className="sm:flex items-center sm:space-x-5 border-b-2 border-secondary_grey sm:pb-7 pb-2">
        <img src={headChat} alt="img" />
        <div className="sm:space-y-2 font-ebGaramond">
          <p className="sm:text-xl text-base">
            We see that you will need a request on:
          </p>
          <p className="sm:text-3xl text-xl">
            Appointment of New Local Director
          </p>
        </div>
      </div>
      <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
        <p className="sm:text-2xl text-xl font-ebGaramond">
          Let us guide u though the process:
        </p>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3 sm:text-base text-sm">
          <span>1.</span>
          <p>How many new local director are you appointing?</p>
        </div>
        <p className="sm:text-sm text-xs">
          Let us know how many new local director you are looking to appoint:
        </p>
        <div>
          <p className="text-xs mb-2">No. of new local director</p>
          <div className="flex items-center max-w-[8rem]">
            <button
              type="button"
              idFromName={"numberOfDirector"}
              onClick={handleEpDecrement}
              className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
            >
              <svg
                className="w-3 h-3 text-theme"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="text"
              id="quantity-input"
              aria-describedby="helper-text-explanation"
              className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
              value={noOfDirector}
              readOnly
            />
            <button
              type="button"
              idFromName={"numberOfDirector"}
              onClick={handleEpIncrement}
              className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
            >
              <svg
                className="w-3 h-3 text-theme"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg sm:text-base text-sm px-4 py-3">
          <span>2.</span>
          <p>Fill in the basic information of the new local director</p>
        </div>
        {directorInformation?.length ? (
          <div className="border-remove-bottom">
            {directorInformation?.map((element, index) => {
              return (
                <div
                  className={`space-y-3 ${
                    !(directorInformation.length - 1 === index) && "pb-5"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <p className="text-sm">
                      Director {!(index === 0) && index + 1} Information:
                    </p>
                    {!(directorInformation.length === 1) && (
                      <button
                        className="p-4"
                        onClick={() => {
                          removeDirectorInformation(index);
                        }}
                      >
                        <Trash />
                      </button>
                    )}
                  </div>
                  <InputWithLabel
                    className={"border"}
                    idFromName={"full_name"}
                    type={"text"}
                    label={"Full Name"}
                    value={element.full_name}
                    onChange={(e) => {
                      updateDirectorInformation(
                        index,
                        e.target.value,
                        element.email
                      );
                    }}
                  />
                  <InputWithLabel
                    className={"border"}
                    idFromName={"email"}
                    type={"email"}
                    label={"Email"}
                    value={element.email}
                    onChange={(e) => {
                      updateDirectorInformation(
                        index,
                        element.full_name,
                        e.target.value
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 sm:text-base text-sm py-3">
          <span>3.</span>
          <p>
            We will send a link to the email provide. We will need the specific
            director to login and provide the relevant information for our KYC
            check
          </p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 sm:text-base text-sm py-3">
          <span>4.</span>
          <p>
            We will do a KYC checked and prepare relevant documents for
            signature
          </p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg sm:text-base text-sm px-4 py-3">
          <span>5.</span>
          <p>Get the relevant document signed and we will handle the rest</p>
        </div>
        <p className="text-sm">
          Once the request is opened. You can easily manage it over at
          “On-Going” service page.
        </p>
      </div>
      <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
        <p className="font-semibold">Total Payable :</p>
        <div>
          <p className="text-xs">{directorInformation.length}/3 used</p>
          {directorInformation?.length >= 3 ? "$450" : "Free"}
        </div>
      </div>
      <div className="box !rounded-xl flex items-center justify-between">
        <button
          disabled={!isValid()}
          className={`${isValid() ? "btn-theme" : "btn-disabled"} uppercase`}
          onClick={() => {
            navigate(
              directorInformation.length < 3 ? "/approver" : "/check-out"
            );
          }}
        >
          Proceed with request
        </button>
        <div>
          <p className="text-xs">Total:</p>
          <p>{directorInformation?.length >= 3 ? "$450" : "Free"}</p>
        </div>
      </div>
    </div>
  );
};

export default LocalDirector;
