import React, { useState } from "react";
import CustomTable from "../../../commons/CustomTable";
import AccordionIndividualService from "./AccordionIndividualService";

const Subscription = () => {
  const [loading, setLoading] = useState();

  // Table Data
  const salesList = {
    data: [
      {
        subscription: "Yearly Subscription",
        billing_cycle: "$500 / Year",
        period: "DD MONTH YYYY - DD MONTH YYYY",
      },
      {
        subscription: "Service",
        billing_cycle: "$1000 / Financial Year",
        period: "DD MONTH YYYY - DD MONTH YYYY",
      },
      {
        subscription: "Service 2",
        billing_cycle: "$1000 / Financial Year",
        period: "DD MONTH YYYY - DD MONTH YYYY",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 3,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          Current Subscription
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Billing Cycle
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Period
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(salesList?.data || [])?.map((element, index) => (
          <AccordionIndividualService element={element} index={index} />
        ))}
      </>
    );
  };
  return (
    <div className="w-full bg-white border rounded">
      <CustomTable
        columnHeaders={<ColumnHeaders />}
        dataRows={<DataRows />}
        data={salesList}
        overflowVisibleLg={true}
        // loader
        loading={loading}
        showPagination={false}
      />
    </div>
  );
};

export default Subscription;
