import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderSvg } from "../assets/svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import AddCompany from "../pages/modal/AddCompany";
import { getAllCompany } from "../redux/companies/slice";

const Modal = ({ isOpen, onClose, setSelectedCompany }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addEditRolePopup, setAddCompanyPopup] = useState(false);
  const modalRef = useRef(null);

  // Selector
  const { GetCompany } = useSelector((store) => ({
    GetCompany: store?.companiesData?.getAllCompanyStatus?.data?.data,
  }));

  // Get APIs
  useEffect(() => {
    dispatch(getAllCompany());
  }, [dispatch]);

  const handleCompanySelect = (company) => {
    localStorage.setItem("selectedCompany", JSON.stringify(company));
    setSelectedCompany(company);
    onClose();
    navigate("/", { state: { selectedCompany: company } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="absolute top-0 left-full flex items-start justify-start ml-1">
          <div
            ref={modalRef}
            className="bg-white px-4 rounded-lg shadow-lg overflow-auto pb-4 h-60 overflow-x-hidden justify-between flex flex-col"
          >
            <ul className="divide-y divide-gray-200">
              {GetCompany?.data?.length > 0 ? (
                GetCompany?.data?.map((company, index) => (
                  <li key={index} className="py-2">
                    <button
                      className="focus:outline-none text-start"
                      onClick={() =>
                        handleCompanySelect({
                          company,
                          companyId: company.id,
                          companyName: company.name,
                          uen: company.uen,
                        })
                      }
                    >
                      <p className="font-poppins font-normal text-sm text-black">
                        {company.name}
                      </p>
                      <p className="font-poppins font-normal text-xs text-black">
                        {company.uen ? "UEN: " + company.uen : ""}
                      </p>
                    </button>
                  </li>
                ))
              ) : (
                <li className="py-2">
                  <p className="text-sm text-black">No companies available</p>
                </li>
              )}
            </ul>
            <button
              className="mt-4 bg-gray-100 hover:bg-gray-200 text-black p-1 items-center flex rounded-md w-44 justify-center"
              onClick={() => {
                setAddCompanyPopup(true);
                onClose();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 7V3H2V21H16V19H12V17H14V15H12V13H14V11H12V9H20V15H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM24 19V21H22V23H20V21H18V19H20V17H22V19H24ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
              <p className="ml-1 font-poppins font-normal text-xs">
                Add Company
              </p>
            </button>
          </div>
        </div>
      )}
      {addEditRolePopup && (
        <AddCompany setAddCompanyPopup={setAddCompanyPopup} />
      )}
    </>
  );
};

export default Modal;
