import React, { useState } from "react";
import { ExpandLess, UserIcon } from "../../assets/svg/AllSvg";
import { Logo } from "../../assets/image";
import { useNavigate } from "react-router-dom";

const Services = ({ element }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="hover:bg-light_secondary_shades">
      <div
        className="cursor-pointer flex justify-between items-start border-b px-6 py-4 mb-5"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="flex items-center space-x-5">
          {element?.icon}
          <div>
            <p className="lg:text-2xl sm:text-lg text-base font-ebGaramond text-theme mb-2">
              {element?.heading}
            </p>
            <p className="sm:text-base text-xs">{element?.subHeading}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-theme border-b inline-block sm:text-base text-xs">
            {isOpen ? "Minimise" : "View"}
          </p>
          {isOpen ? <ExpandLess /> : <ExpandLess className="rotate-180" />}
        </div>
      </div>
      {isOpen && (
        <>
          {element.service === "LocalDirector" && (
            <>
              <div className="flex space-x-20 border-sp px-4">
                <div className="flex items-center space-x-3">
                  <div className="sm:w-6 sm:h-6 w-4 h-4 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="sm:text-sm text-xs ">
                    <p className="sm:font-semibold font-bold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="sm:w-6 sm:h-6 w-4 h-4 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="sm:text-sm text-xs">
                    <p className="sm:font-semibold font-bold">Invitation to</p>
                    <p>join company</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="sm:w-6 sm:h-6 w-4 h-4 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="sm:text-sm text-xs">
                    <p className="sm:font-semibold font-bold">Due Diligence</p>
                    <p>on new director</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="sm:w-6 sm:h-6 w-4 h-4 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">EiBiz Processing</p>
                    <p>if Documents</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 ">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 lg:border-r border-0">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="sm:text-sm text-xs text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme sm:text-base text-sm">{`< 15 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey sm:text-sm text-xs">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme sm:text-base text-sm">
                        4 to 5 working days
                      </p>
                    </div>
                    <div>
                      <p className=" text-gray-400 mb-1 sm:text-sm text-xs">
                        Price:
                      </p>
                      <p className="text-theme sm:text-base text-sm">
                        Free (0/3)
                      </p>
                    </div>
                  </div>
                  <div className="lg:text-xl sm:text-sm text-xs">
                    <p className="mb-1">What will you need?</p>
                    <p>
                      - Create a request for a new director joining your company
                    </p>
                  </div>
                </div>
                <div className="lg:pl-5 lg:mt-0 mt-3">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "BankAccount" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Bank</p>
                    <p>Selection</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Acknowledge</p>
                    <p>requirements</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Head down</p>
                    <p>to the bank</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 5 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">Instant</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ChangeCompanyName" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">EiBiz Processing</p>
                    <p>Documents</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending your</p>
                    <p>Signatures</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 15 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">
                        5 - 2 months working days
                        <br />
                        (for referred cases)
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>
                      - All suggested new names will undergo availability checks
                      with ACRA.
                    </p>
                    <p>
                      - ACRA imposes a $15 fee for name reservation, which EiBIZ
                      has graciously got you covered.
                    </p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ChangeCompanyActivities" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Process</p>
                    <p>required information</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending your</p>
                    <p>Signatures</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 15 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">1 to 3 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>
                      - Descriptions of activities must be limited to 75 words.
                    </p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ChangeCompanyAddress" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">EiBiz Processing</p>
                    <p>Documents</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending your</p>
                    <p>Signatures</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 5 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">4 to 5 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>
                      - An updated address
                      <br />- Signing of documents
                    </p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ChangeParticulars" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">EiBiz Processing</p>
                    <p>Your Documents</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending your</p>
                    <p>Signatures</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 5 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">4 to 5 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>- The updated particulars</p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ChangeFinancialYear" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Process</p>
                    <p>information</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending your</p>
                    <p>Signatures</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">ACRA</p>
                    <p>Filing</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 5 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">4 to 5 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Free (0/3)</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>- Your ideal Financial Year End period</p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "NewShareholders" && (
            <>
              <div className="grid grid-cols-6 px-6 py-4">
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 rounded-full flex justify-center  p-1 items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Add shareholders</p>
                    <p>if any</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Configure</p>
                    <p>Cap table</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center  p-1 bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if upgrade required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Invite to</p>
                    <p>join company</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Due Diligence</p>
                    <p>of new shareholders if have</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">EiBIZ</p>
                    <p>Processing documents for signatures</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 15 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">3 to 4 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Conditional</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>
                      - Details of new shareholders (if applicable) <br />
                      - Revised shareholding structure <br />- Proof of
                      remittance, recent financial statements/management
                      accounts, or a contract . drafted by a lawyer
                    </p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
          {element.service === "ApplyingPasses" && (
            <>
              <div className="grid grid-cols-5 px-6 py-4">
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/2">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <UserIcon />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Payment</p>
                    <p>if required</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center p-1 items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Process</p>
                    <p>information</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 relative after:w-1/3 after:h-1 after:border-b-2 after:border-secondary_grey after:absolute after:right-0 after:top-1/2 after:-translate-x-1/3">
                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">Pending</p>
                    <p>documents</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 p-1 rounded-full flex justify-center items-center bg-theme">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="text-sm">
                    <p className="font-semibold">MOM</p>
                    <p>Submission</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center border-b px-6 py-4">
                <div className="col-span-2 border-r">
                  <div className="flex space-x-4 mb-3">
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Filling of Form:
                      </p>
                      <p className="text-theme">{`< 5 mins`}</p>
                    </div>
                    <div className="pr-4 border-r-2 border-secondary_grey">
                      <p className="text-sm text-gray-400 mb-1">
                        Processing Time:
                      </p>
                      <p className="text-theme">4 to 5 working days</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 mb-1">Price:</p>
                      <p className="text-theme">Conditional</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-1">What will you need?</p>
                    <p>- Documents and details of the hire</p>
                  </div>
                </div>
                <div className="pl-5">
                  <button
                    className="btn-theme"
                    onClick={() => {
                      navigate(`/${element.appointment}`);
                    }}
                  >
                    Create A Service request
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Services;
