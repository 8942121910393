import React, { useState } from "react";
import Action from "../../../commons/Action";
import { ExpandLess } from "../../../assets/svg/AllSvg";

const AccordionIndividualService = ({ element }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <tr
        className={
          "border-b table_responsive_text hover:bg-secondary_light_shades"
        }
        key={element?.id}
      >
        <td className="px-5 py-3 truncate">{element.subscription}</td>
        <td className="px-5 py-3 truncate">{element.billing_cycle}</td>
        <td className="px-5 py-3 truncate">{element.period}</td>
        <td className="px-5 py-3">
          <div className="flex items-center space-x-2">
            <Action
              optionList={
                <>
                  <li
                    className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                    onClick={() => {
                      "";
                    }}
                  >
                    View
                  </li>
                </>
              }
            />
            <button type="button" onClick={() => setIsOpen(!isOpen)}>
              <ExpandLess pathClass={"fill-secondary_lightGrey"} />
            </button>
          </div>
        </td>
      </tr>
      {isOpen ? (
        <>
          <tr className="bg-primary_shades_light">
            <td className="px-5 py-3" colspan="4">
              Service name
            </td>
          </tr>
          <tr className="bg-lightPrimaryShades">
            <td className="px-8 py-2">Billing Cycle</td>
            <td className="px-8 py-2" colspan="3">
              $576 / Year
            </td>
          </tr>
          <tr className="bg-lightPrimaryShades">
            <td className="px-8 py-2">Date of Purchase</td>
            <td className="px-8 py-2" colspan="3">
              23 Feb 2023
            </td>
          </tr>
          <tr className="bg-lightPrimaryShades">
            <td className="px-8 py-2">Service Period</td>
            <td className="px-8 py-2" colspan="3">
              01 Feb 2024 - 31 Dec 2024
            </td>
          </tr>
          <tr className="bg-lightPrimaryShades border-b">
            <td className="px-8 py-2">Status</td>
            <td className="px-8 py-2" colspan="3">
              On-Going
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
};

export default AccordionIndividualService;
