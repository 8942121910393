import React, { useEffect, useState } from "react";
import { womanSpeaker } from "../../assets/image";
import ReactSelect from "react-select";
import CustomTable from "../../commons/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { CheckBox } from "../../components/micro";
import { ArrowUp2 } from "iconsax-react";
import moment from "moment";
import {
  deleteNotifications,
  getNotificationsList,
  readNotifications,
} from "../../redux/notifications/slice";
import ViewNotification from "./ViewNotification";
import ConfirmationModel from "../../commons/ConfirmationModel";
import Action from "../../commons/Action";

const Notifications = () => {
  const [selectedBulkAction, setSelectedBulkAction] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [viewNotification, setViewNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [notificationsListCheck, setNotificationsListCheck] = useState([]);
  const [allCheckedNotifications, setAllCheckedNotifications] = useState([]);
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const [selectAll, setSelectAll] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch();

  // Bulk Action option
  const bulkAction = [
    { value: "read", label: "Read" },
    { value: "delete", label: "Delete" },
  ];

  // Selector
  const { notificationsData, loading } = useSelector((store) => ({
    notificationsData:
      store?.notificationsData?.getNotificationsListStatus?.data?.data,
    loading: store?.notificationsData?.loading,
  }));

  useEffect(() => {
    dispatch(
      getNotificationsList({
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: addSort,
        sort_direction: addSortDirection,
      })
    );
  }, [dispatch, pageCount, pageLimit, addSort, addSortDirection]);

  // Bulk Action
  const handleBulkAction = (e) => {
    if (allCheckedNotifications?.length) {
      if (e === "delete") {
        dispatch(deleteNotifications({ id: allCheckedNotifications })).then(
          (res) => {
            setSelectAll(false);
            setConfirmDelete(false);
            if (res.type === "deleteNotifications/fulfilled") {
              setSelectedBulkAction(null);
              dispatch(
                getNotificationsList({
                  pageCount: pageCount,
                  pageLimit: pageLimit,
                  sort_by: addSort,
                  sort_direction: addSortDirection,
                })
              );
            }
          }
        );
      } else if (e === "read") {
        dispatch(
          readNotifications({ id: allCheckedNotifications, read: true })
        ).then((res) => {
          if (res.type === "readNotifications/fulfilled") {
            setSelectedBulkAction(null);
            setSelectAll(false);
            dispatch(
              getNotificationsList({
                pageCount: pageCount,
                pageLimit: pageLimit,
                sort_by: addSort,
                sort_direction: addSortDirection,
              })
            );
          }
        });
      }
    }
  };

  // set Notification Data on check box
  useEffect(() => {
    if (notificationsData?.data) {
      setNotificationsListCheck(notificationsData?.data ?? []);
    }
  }, [notificationsData?.data]);

  // Handle Select All Notification
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedItems = notificationsListCheck?.map((item) => ({
      ...item,
      checked: newSelectAll,
    }));
    setNotificationsListCheck(updatedItems);
  };

  // Handle Select One Notification
  const handleIndividualChange = (id) => {
    const updatedItems = notificationsListCheck.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setNotificationsListCheck(updatedItems);

    // Check if all individual checkboxes are checked to update "Select All" checkbox
    const allChecked = updatedItems.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  // Filter Of selected Notification
  useEffect(() => {
    const checkedNotifications = notificationsListCheck.filter(
      (item) => item.checked
    );
    const checkedNotificationsId = checkedNotifications?.map((e) => e.id);
    setAllCheckedNotifications(checkedNotificationsId);
  }, [notificationsListCheck]);

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          <CheckBox
            label={"No."}
            name={"allCheck"}
            idFrom={"allCheck"}
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Notification
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Date/Time </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" ? "rotate-180" : ""
              }`}
              onClick={() => {
                setAddSort("created_at");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowUp2 size="16" />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(notificationsListCheck || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">
              <CheckBox
                idFrom={element?.id}
                label={
                  (notificationsData?.current_page - 1) *
                    notificationsData?.per_page +
                  index +
                  1
                }
                name={element?.id}
                checked={element?.checked}
                onChange={() => handleIndividualChange(element?.id)}
              />
            </td>
            <td className="px-5 py-3 truncate">
              <span
                className={`text-[#303F9F] border-b border-[#303F9F] capitalize cursor-pointer ${
                  element?.is_read === 0 && "font-semibold"
                }`}
                onClick={() => {
                  setNotificationData(element);
                  setViewNotification(true);
                }}
              >
                {element?.content}
              </span>
            </td>
            <td className="px-5 py-3 truncate">
              {moment(element?.created_at).format("DD/MM/YYYY | HH:MM A")}
            </td>
            <td className="px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setNotificationData(element);
                        setViewNotification(true);
                      }}
                    >
                      View
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setNotificationData(element);
                        setAllCheckedNotifications([element?.id]);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center gap-4">
          <div className="w-20 h-20">
            <img
              src={womanSpeaker}
              alt="womanSpeaker"
              className="w-full h-full object-cover"
            />
          </div>
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond font-normal">
            Notifications
          </h1>
        </div>
        <ReactSelect
          className={"w-full max-w-xs text-black p-0"}
          id="bulkAction"
          placeholder="Bulk Action"
          options={bulkAction}
          getOptionValue={(bulkActionList) => bulkActionList.value}
          value={selectedBulkAction}
          onChange={(e) => {
            setSelectedBulkAction(e);
            handleBulkAction(e?.value);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={notificationsData}
            overflowVisibleLg={true}
            // loader
            loading={loading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={notificationsData?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>

      {/* Delete Notification Confirmation Model */}
      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            handleBulkAction("delete");
          }}
        />
      )}
      {/* View Notification */}
      {viewNotification && (
        <ViewNotification
          pageCount={pageCount}
          pageLimit={pageLimit}
          onClose={() => {
            setViewNotification(false);
          }}
          notificationData={notificationData}
          sort_by={addSort}
          sort_direction={addSortDirection}
        />
      )}
    </>
  );
};

export default Notifications;
