import React, { useState } from "react";
import { BackButton } from "../../components/micro";
import { useNavigate } from "react-router-dom";
import { HandCreditCard, HandCreditCardIcon } from "../../assets/svg/AllSvg";
import AddNewCard from "./components/AddNewCard";
import Action from "../../commons/Action";

const MyPaymentMethod = () => {
  const [addNewCard, setAddNewCard] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {/* Page Header */}
      <div>
        <BackButton
          onClick={() => navigate("/billing-subscriptions")}
          className={"mb-3"}
        />
        <div className="flex items-center space-x-3 border-b-2 pb-5 mb-5">
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond">
            Payment method
          </h1>
          <div className="max-w-14 w-full">
            <HandCreditCard className="w-full" />
          </div>
        </div>
        {/* Card List */}
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className="bg-custom-gradient-1 text-white rounded-xl relative">
            <div className="py-4 px-4">
              <div className="flex items-center space-x-2">
                <span className="w-3 h-3 rounded-full border-2 border-white bg-[#16A88F]"></span>
                <p>Default Card</p>
              </div>
              <div className="absolute top-3 right-3">
                <Action
                  optionList={
                    <>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Edit
                      </li>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Set as Default
                      </li>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Delete
                      </li>
                    </>
                  }
                  fillOpacity={1}
                  fillClass={"fill-white"}
                />
              </div>
              <p className="py-5">**** **** **** 1234</p>
            </div>
            <div className="flex justify-between bg-white/25 py-3 px-4 ">
              <div className="grid grid-cols-2 uppercase text-sm gap-5">
                <dispatchEvent>
                  <p className="text-xs opacity-70 mb-1">CARD HOLDER</p>
                  <p>MAMANG ZAHRI</p>
                </dispatchEvent>
                <div>
                  <p className="text-xs opacity-70 mb-1">EXPIRED</p>
                  <p>10/26</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-custom-gradient-2 text-white rounded-xl relative">
            <div className="py-4 px-4">
              <div className="flex items-center space-x-2">
                <span className="w-3 h-3 rounded-full border-2 border-white bg-[#16A88F]"></span>
                <p>Default Card</p>
              </div>
              <div className="absolute top-3 right-3">
                <Action
                  optionList={
                    <>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Edit
                      </li>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Set as Default
                      </li>
                      <li className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Delete
                      </li>
                    </>
                  }
                  fillOpacity={1}
                  fillClass={"fill-white"}
                />
              </div>
              <p className="py-5">**** **** **** 1234</p>
            </div>
            <div className="flex justify-between bg-white/25 py-3 px-4 ">
              <div className="grid grid-cols-2 uppercase text-sm gap-5">
                <dispatchEvent>
                  <p className="text-xs opacity-70 mb-1">CARD HOLDER</p>
                  <p>MAMANG ZAHRI</p>
                </dispatchEvent>
                <div>
                  <p className="text-xs opacity-70 mb-1">EXPIRED</p>
                  <p>10/26</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add New Card */}
        <div className="card border-2 border-dashed !rounded-2xl bg-white flex justify-center !py-16">
          <div className="flex flex-col items-center">
            <HandCreditCardIcon className="max-w-9 mb-3" />
            <button
              className="btn-theme uppercase"
              onClick={() => setAddNewCard(true)}
            >
              Add New Card
            </button>
          </div>
        </div>
      </div>
      {/* Add New Card Pop Up */}
      {addNewCard && <AddNewCard onClose={() => setAddNewCard(false)} />}
    </>
  );
};

export default MyPaymentMethod;
