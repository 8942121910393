import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import layoutContext from "./layoutContext";
import { FestivalIcon, OutVector } from "../assets/svg/AllSvg";
import { Logo } from "../assets/image";
import { useDispatch, useSelector } from "react-redux";
import SidebarDropDown from "../commons/SidebarDropDown";
import Modal from "./modal";
import { getAllCompany } from "../redux/companies/slice";
import RippleLoader from "../commons/RippleLoader";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isAsideOpen } = useContext(layoutContext);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const { GetCompany, getProfileData, loading } = useSelector((store) => ({
    getProfileData: store?.authData?.getProfileDataStatus?.data,
    loading: store?.authData?.loading,
    GetCompany: store?.companiesData?.getAllCompanyStatus?.data?.data,
  }));

  // Fetch companies
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllCompany());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const companyID = selectedCompany
      ? selectedCompany.companyId
      : GetCompany?.data[0]?.id;
    if (companyID) {
      sessionStorage.setItem("companyID", companyID);
    } else if (GetCompany?.length) {
      const defaultCompanyId = GetCompany[0]?.id;
      sessionStorage.setItem("companyID", defaultCompanyId);
    }
  }, [GetCompany, selectedCompany]);

  const companyId = sessionStorage.getItem("companyID");

  const companies = Array.isArray(GetCompany?.data) ? GetCompany.data : [];
  const company = companies?.find(
    (company) => company.id === parseInt(companyId, 10)
  );

  const logOutClear = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const displayedCompany = selectedCompany?.companyName || company?.name;

  const displayedUen = selectedCompany?.uen || company?.uen;

  return (
    <>
      <aside
        className={`${
          isAsideOpen ? "w-[16rem] bg-white bottom-0" : "md:w-[5rem] w-0 top-0"
        } fixed z-[99] md:relative flex flex-col justify-between md:overflow-visible overflow-hidden transition-all border-r h-[calc(100vh-4rem)]`}
      >
        <div className="h-full">
          {loading ? (
            <RippleLoader />
          ) : (
            <div className="relative flex items-center p-4 border-b border-gray-200 gap-x-2 bg-primary_shades_light justify-between">
              <div className="flex gap-1">
                <FestivalIcon />
                {isAsideOpen && (
                  <>
                    {displayedCompany ? (
                      <div className="mr-2">
                        <p className="font-poppins font-normal text-sm text-secondary -tracking-wide">
                          {displayedCompany ? displayedCompany : "No company"}
                        </p>
                        <p className="font-poppins font-normal text-xs text-theme">
                          {displayedCompany && displayedUen != null
                            ? `UEN: ${displayedUen}`
                            : ""}
                        </p>
                      </div>
                    ) : (
                      <p>No company</p>
                    )}
                  </>
                )}
              </div>
              <button onClick={openModal}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
              </button>
              <Modal
                isOpen={isOpen}
                onClose={closeModal}
                setSelectedCompany={setSelectedCompany}
              />
            </div>
          )}

          <ul
            className={` h-[calc(100vh-12.9rem)] ${
              isAsideOpen ? "overflow-y-auto" : "overflow-visible"
            }  `}
          >
            {getProfileData?.permission_list?.map((menu, index) => (
              <>
                {menu?.subMenu?.length ? (
                  <SidebarDropDown isAsideOpen={isAsideOpen} menu={menu} />
                ) : (
                  <li key={index} className={`group aside-links tooltipBlock`}>
                    <button
                      className={`flex items-center relative z-[99999] ${
                        !isAsideOpen
                          ? "px-2 hover:translate-x-0 hover:bg-light_secondary_shades my-1 justify-center"
                          : "hover:translate-x-1 px-4 "
                      }  py-2 gap-x-4 h-auto  hover:text-black w-full rounded-lg transition duration-200  ${
                        location.pathname ===
                          `/${menu.path === "dashboard" ? "" : menu?.path}` &&
                        `bg-light_secondary_shades`
                      }`}
                      onClick={() => {
                        const targetPath =
                          menu?.path === "dashboard" ? "/" : menu?.path;
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(selectedCompany)
                        );
                        navigate(targetPath, { state: { selectedCompany } });
                      }}
                    >
                      <div className="max-w-5 max-h-5 w-full">
                        <img
                          src={menu?.icon_path ?? Logo}
                          className="w-full h-full object-contain"
                          alt="icon"
                        />
                      </div>
                      <p
                        className={`origin-left font-medium duration-250 flex items-center text-left  ${
                          !isAsideOpen && "hidden"
                        }`}
                      >
                        {menu.title}
                      </p>
                      {!isAsideOpen && (
                        <div
                          id="tooltip"
                          className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
                        >
                          <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                          <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                            {menu.title}
                          </div>
                        </div>
                      )}
                    </button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
        {/* Log Out */}
        <div
          className="p-4 flex items-center border-t hover:bg-light_secondary_shades"
          onClick={() => logOutClear()}
        >
          <button
            className={`flex items-center relative z-[99999] ${
              !isAsideOpen
                ? "px-2 hover:translate-x-0 py-1 justify-center"
                : "hover:translate-x-1 px-4 "
            } gap-x-4 h-auto hover:text-black w-full rounded-lg transition duration-200`}
          >
            <OutVector />
            <p
              className={`origin-left font-medium duration-250 flex  items-center  ${
                !isAsideOpen && "hidden"
              }   `}
            >
              Log Out
            </p>
            {!isAsideOpen && (
              <div
                id="tooltip"
                className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
              >
                <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                  <OutVector />
                </div>
              </div>
            )}
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
