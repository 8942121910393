import React, { useState } from "react";
import LocalResidentDirector from "./LocalResidentDirector";
import NomineeDirector from "./NomineeDirector";
import { Logo } from "../../../assets/index";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DirectorsShareholders = ({
  shareholders,
  resident_directors,
  nominee_directors,
  nominee_director_durations,
  nominee_director_deposits,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  // Selector
  const { CreateCompany } = useSelector((store) => ({
    CreateCompany: store?.companiesData?.CreateCompanyStatus?.data?.data,
  }));

  const tabs = [
    {
      text: "Use my local resident director ",
      id: 0,
      content: (
        <LocalResidentDirector
          company_id={CreateCompany?.id}
          resident_directors={resident_directors}
          shareholders={shareholders}
        />
      ),
      link: "Eligibility Criteria",
      linkUrl:
        "https://www.singstat.gov.sg/standards/standards-and-classifications/ssic",
    },
    {
      text: "Use EiBIZ's Nominee Director",
      subText:
        "If you are based overseas or need a time buffer to get a local director",
      icon: true,
      id: 1,
      content: (
        <NomineeDirector
          company_id={CreateCompany?.id}
          nominee_directors={nominee_directors}
          shareholders={shareholders}
          nominee_director_durations={nominee_director_durations}
          nominee_director_deposits={nominee_director_deposits}
        />
      ),
    },
  ];

  return (
    <div className="col-span-5 bg-white border rounded py-5 px-6">
      <div className="border-b border-secondary_grey pb-2 mb-5">
        <p className="sm:text-md text-sm mb-1">Thirdly,</p>
        <p className="font-ebGaramond lg:text-2xl sm:text-xl text-base sm:mb-3 mb-1">
          Invite company members
        </p>
      </div>
      {/* tab head */}
      <div className="grid lg:grid-cols-2 lg:gap-5 gap-3 mb-5">
        {tabs?.map((element) => {
          return (
            <div
              className={`p-4 ${
                activeTab === element?.id ? "bg-light_theme" : "bg-white"
              } cursor-pointer rounded-lg border-2 space-y-3`}
              onClick={() => {
                setActiveTab(element?.id);
              }}
            >
              <div className="flex space-x-5">
                {element?.icon && (
                  <img
                    src={Logo}
                    className="w-11 h-11 object-contain"
                    alt="icon"
                  />
                )}
                <div className="space-y-3 w-full">
                  <p className="sm:text-base text-sm">{element?.text}</p>
                  {element?.subText && (
                    <p className="sm:text-sm text-xs text-light_grey">
                      {element?.subText}
                    </p>
                  )}
                </div>
              </div>
              {element?.link && (
                <Link
                  target="_blank"
                  to={element?.linkUrl}
                  className="text-theme border-b  sm:text-base text-sm  border-theme"
                >
                  {element?.link}
                </Link>
              )}
            </div>
          );
        })}
      </div>
      {/* tab content */}
      {tabs?.map((element) => activeTab === element?.id && element?.content)}
    </div>
  );
};

export default DirectorsShareholders;
