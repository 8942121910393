import React, { useState } from "react";
import CustomTable from "../../../commons/CustomTable";
import Action from "../../../commons/Action";

const SalesTransactions = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);

  // Table Data
  const salesList = {
    data: [
      {
        id: "0001",
        transaction_type: "Service Request",
        date: "04/04/2024",
        amount: "$500",
        head: "Cell",
      },
      {
        id: "0002",
        transaction_type: "Subscription",
        date: "04/04/2024",
        amount: "$500",
        head: "Cell",
      },
      {
        id: "0003",
        transaction_type: "Subscription",
        date: "04/04/2024",
        amount: "$500",
        head: "Cell",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 3,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          ID..{" "}
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Transaction Type
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Date
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Amount
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Head
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(salesList?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">{element.id}</td>
            <td className="px-5 py-3 truncate">{element.transaction_type}</td>
            <td className="px-5 py-3 truncate">{element.date}</td>
            <td className="px-5 py-3 truncate">{element.amount}</td>
            <td className="px-5 py-3 truncate">{element.head}</td>
            <td className="px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        "";
                      }}
                    >
                      View
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="w-full bg-white border rounded">
      <CustomTable
        columnHeaders={<ColumnHeaders />}
        dataRows={<DataRows />}
        data={salesList}
        overflowVisibleLg={true}
        // loader
        loading={loading}
        showPagination={true}
        // showLimit
        setPaginationCurrentLimit={setPageLimit}
        paginationCurrentLimit={pageLimit}
        // paginationData
        total={salesList?.total}
        current={pageCount}
        paginationData={(crPage) => setPageCount(crPage)}
      />
    </div>
  );
};

export default SalesTransactions;
