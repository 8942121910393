import React, { useEffect } from "react";
import { useState } from "react";
import { useDebounce } from "./MicroComponents";
import { Search } from "../assets/svg/AllSvg";

const DebounceSearch = ({
  search,
  handleChange,
  add2Chardelay = true,
  placeholder,
}) => {
  const [searchLocal, setSearchLocal] = useState("");
  const debounceSearch = useDebounce(searchLocal);

  const handleSearch = (e) => {
    const value = e?.target?.value || "";
    setSearchLocal(value);
  };

  useEffect(() => {
    if (
      (add2Chardelay &&
        (debounceSearch?.length === 0 || debounceSearch?.length > 1)) ||
      !add2Chardelay
    ) {
      handleChange(debounceSearch || "");
    }
  }, [debounceSearch]);

  useEffect(() => {
    setSearchLocal(search);
  }, [search]);

  return (
    <>
      <div className="relative w-full sm:max-w-xs">
        <Search className="absolute top-1/2 -translate-y-1/2 translate-x-3" />
        <input
          type="search"
          onChange={handleSearch}
          value={searchLocal}
          className="w-full py-2 pr-4 pl-10 bg-white border rounded border-theme/20 lg:text-base sm:text-sm text-xs focus:border-theme/80"
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

export default DebounceSearch;
