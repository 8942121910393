import React, { useContext, useEffect, useState } from "react";
import { goOn, Home } from "../../assets/image";
import { useDispatch, useSelector } from "react-redux";
import { Address, createAddress } from "../../redux/companies/slice";
import { getCountryData } from "../../redux/address/slice";
import ReactSelect from "react-select";
import CompanyContext from "./CompanyContext";
import { BackArrow } from "../../assets/svg/AllSvg";
import { InputError } from "../../commons/MicroComponents";
import {
  Badge,
  CheckBox,
  InputWithLabel,
  RadioButton,
} from "../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../commons/ButtonLoader";
import { getOrganisationDetails } from "../../redux/organisation/slice";
import { Link } from "react-router-dom";

const CompanyAddress = ({
  companyId,
  addresses,
  business_registered_address_fee,
}) => {
  const [selectedOption, setSelectedOption] = useState("Registered Address");
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [addressTypeValue, setAddressTypeValue] = useState();
  const [operationsAddressTypeValue, setOperationsAddressTypeValue] =
    useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { prev, next } = useContext(CompanyContext);

  // Selector for Get APIs Response
  const { AddressData, countryData } = useSelector((store) => ({
    AddressData: store?.companiesData?.AddressStatus?.data?.data,
    countryData: store?.addressData?.getCountryDataStatus?.data,
  }));

  // Get Address, Country API
  useEffect(() => {
    dispatch(Address());
    dispatch(getCountryData());
  }, []);

  // Change Format of Country Data
  useEffect(() => {
    if (countryData?.data) {
      const data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
  }, [countryData]);

  useEffect(() => {
    if (addresses?.length) {
      setSelectedOption(
        addresses?.length === 1 || !addresses?.length
          ? "Registered Address"
          : "Company Address"
      );
    }
  }, [addresses]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.id }));
      setCountryArray(data);
    }
  }, [countryData]);

  useEffect(() => {
    if (addresses?.length) {
      const AddressTypeValue = {
        label: addresses[0]?.address_type,
        value: addresses[0]?.address_type,
      };
      const AddressType2Value = {
        label: addresses[1]?.address_type,
        value: addresses[1]?.address_type,
      };
      setAddressTypeValue(AddressTypeValue);
      setOperationsAddressTypeValue(AddressType2Value);
    }
  }, [addresses]);

  return (
    <>
      <div className="card border p-4 drop-shadow-lg bg-white">
        <div className="p-2">
          <p className="font-poppins sm:text-sm text-xs">Secondly</p>
          <p className="font-ebGaramond font-normal lg:text-2xl sm:text-xl text-base mb-3">
            We will need to know your Company address
          </p>
          {/* 1 */}
          <div className="grid lg:grid-cols-2 lg:gap-5 gap-3 border-t pt-4 mb-3">
            <div
              className={`w-full border px-4 rounded-md cursor-pointer ${
                selectedOption === "Registered Address"
                  ? "bg-secondaryGray"
                  : "hover:bg-lightPrimaryShades"
              }`}
              onClick={() => handleOptionChange("Registered Address")}
            >
              <div className="w-full flex gap-3 py-4">
                <img className="sm:w-15 sm:h-15 w-10 h-10" src={Home} />
                <div className="w-full">
                  <p className="font-poppins sm:text-sm lg:text-base text-xs font-normal text-start">
                    Use EiBiz’s registered address & Digital Mailroom Service
                  </p>
                  <div className="flex justify-between bg-lightPrimaryShades p-2 mt-2">
                    <p className="font-poppins text-xs font-light">Price</p>
                    <p className="font-poppins text-xs font-light">
                      Annual Fees <br />
                      <p className="font-normal">
                        ${business_registered_address_fee}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`w-full border p-2 rounded-md cursor-pointer ${
                selectedOption === "Company Address"
                  ? "bg-lightPrimaryShades"
                  : "hover:bg-lightPrimaryShades"
              }`}
              onClick={() => handleOptionChange("Company Address")}
            >
              <div className="w-full flex pt-2">
                <img className="sm:w-15 sm:h-15 w-10 h-10" src={Home} />
                <div className="w-full py-1 px-3">
                  <p className="font-poppins sm:text-sm lg:text-base text-xs font-normal text-start mb-3">
                    Use your own company address
                  </p>
                  <button
                    type="button"
                    className="border-b border-blue-700 text-blue-700 font-normal inline-block text-xs font-poppins"
                  >
                    Eligibility Criteria
                  </button>
                </div>
              </div>
            </div>
          </div>

          {selectedOption === "Registered Address" && (
            <>
              <p className="sm:px-3 font-poppins font-normal lg:text-xl  sm:text-base text-sm lg:mb-4 mb-2">
                1. Registered Office Address
              </p>
              <div className="border p-2 mb-4 rounded-md flex py-4 bg-lightBackgroundDefault">
                <div>
                  <img className="sm:w-15 sm:h-15 w-10 h-10" src={Home} />
                </div>
                <div className="px-3">
                  <Badge
                    value={"Your EiBiz Registered Address"}
                    className={
                      "font-normal inline-block bg-lightGreen text-white font-poppins sm:text-sm text-xs !py-1 !px-2"
                    }
                  />

                  {AddressData ? (
                    <div>
                      <p className="font-poppins lg:text-xl  sm:text-base text-sm font-normal mt-2">
                        Registered Office Address
                      </p>
                      <div className="mt-2 text-light_grey">
                        <p className="font-poppins sm:text-sm text-xs font-normal">
                          Address Line 1:
                        </p>
                        <p className="font-poppins sm:text-base text-sm font-light sm:mb-0 mb-2">
                          {AddressData?.address_line_1 ?? "-"}
                        </p>
                        <p className="font-poppins sm:text-sm text-xs font-normal">
                          Address Line 2:
                        </p>
                        <p className="font-poppins sm:text-base text-sm font-light sm:mb-0 mb-2">
                          {AddressData?.address_line_2 ?? "-"}
                        </p>
                        <p className="font-poppins sm:text-sm text-xs font-normal">
                          Postal Code:
                        </p>
                        <p className="font-poppins sm:text-base text-sm font-light sm:mb-0 mb-2">
                          {AddressData?.postal_code ?? "-"}
                        </p>
                        <p className="font-poppins sm:text-sm text-xs font-normal">
                          Country :
                        </p>
                        <p className="font-poppins sm:text-base text-sm font-light sm:mb-0 mb-2">
                          {AddressData?.country?.name ?? "-"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p>No address data available</p>
                  )}
                </div>
              </div>

              {/* 2 */}
              <div className="space-y-3 border-t pt-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    address_type: addressTypeValue ?? "",
                    address_line_1:
                      addresses?.length && addresses[0]?.address?.address_line_1
                        ? addresses[0]?.address?.address_line_1
                        : "",
                    address_line_2:
                      addresses?.length && addresses[0]?.address?.address_line_2
                        ? addresses[0]?.address?.address_line_2
                        : "",
                    postal_code:
                      addresses?.length && addresses[0]?.address?.postal_code
                        ? addresses[0]?.address?.postal_code
                        : "",
                    countryId:
                      addresses?.length && addresses[0]?.address?.country_id
                        ? addresses[0]?.address?.country_id
                        : "",
                    physical:
                      addresses?.length && addresses[0]?.physical_store === 0
                        ? false
                        : true,
                    physical_store_address: {
                      same_as_above:
                        addresses?.length &&
                        addresses[0]?.address?.address_line_1 ===
                          addresses[0]?.physical_store_address?.address_line_1
                          ? true
                          : false,
                      address_line_1:
                        addresses?.length &&
                        addresses[0]?.physical_store_address?.address_line_1
                          ? addresses[0]?.physical_store_address?.address_line_1
                          : "",
                      address_line_2:
                        addresses?.length &&
                        addresses[0]?.physical_store_address?.address_line_2
                          ? addresses[0]?.physical_store_address?.address_line_2
                          : "",
                      postal_code:
                        addresses?.length &&
                        addresses[0]?.physical_store_address?.postal_code
                          ? addresses[0]?.physical_store_address?.postal_code
                          : "",
                      countryId:
                        addresses?.length &&
                        addresses[0]?.physical_store_address?.country_id
                          ? addresses[0]?.physical_store_address?.country_id
                          : "",
                    },
                  }}
                  validationSchema={Yup.object().shape({
                    address_type: Yup.object()
                      .shape({
                        value: Yup.string().required(
                          "Please Select Address Type"
                        ),
                        label: Yup.string().required(
                          "Please Select Address Type"
                        ),
                      })
                      .nullable()
                      .required("Please Select Address Type"),
                    address_line_1: Yup.string().required(
                      "Please Enter Address Line 1"
                    ),
                    address_line_2: Yup.string().nullable(),
                    postal_code: Yup.string()
                      .required("Please Enter Postal Code")
                      .length(5, "Postal Code must be exactly 5 characters")
                      .matches(/^\d+$/, "Postal Code must be numeric"),
                    countryId: Yup.string()
                      .nullable()
                      .required("Please select a country"),
                    physical: Yup.boolean()
                      .required("Please Select Do you operate a physical store")
                      .oneOf(
                        [true, false],
                        "Please Select Do you operate a physical store"
                      ),
                    physical_store_address: Yup.object().when("physical", {
                      is: true,
                      then: Yup.object().shape({
                        address_line_1: Yup.string().required(
                          "Please Enter Address Line 1"
                        ),
                        postal_code: Yup.string()
                          .required("Please Enter Postal Code")
                          .length(5, "Postal Code must be exactly 5 characters")
                          .matches(/^\d+$/, "Postal Code must be numeric"),
                        countryId: Yup.string()
                          .nullable()
                          .required("Please Select Country"),
                      }),
                      otherwise: Yup.object().notRequired(),
                    }),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    let type;

                    if (selectedOption === "Registered Address") {
                      type = "registered";
                    } else if (selectedOption === "Company Address") {
                      type = "own";
                    } else {
                      type = "default";
                    }
                    dispatch(
                      createAddress({
                        company_id: companyId,
                        type,
                        details: [
                          {
                            sub_type: "business_operational",
                            address_type: values.address_type?.value,
                            address_line_1: values.address_line_1,
                            address_line_2: values.address_line_2 || "",
                            postal_code: values.postal_code,
                            country_id: values.countryId,
                            physical_store: values.physical || false,
                            physical_store_address: values.physical
                              ? {
                                  same_as_above: values.store_address || false,
                                  address_line_1: values.store_address
                                    ? values.address_line_1
                                    : values.physical_store_address
                                        .address_line_1 || "",
                                  address_line_2: values.store_address
                                    ? values.address_line_2
                                    : values.physical_store_address
                                        .address_line_2 || "",
                                  postal_code: values.store_address
                                    ? values.postal_code
                                    : values.physical_store_address.postal_code,
                                  country_id: values.store_address
                                    ? values.countryId
                                    : values.physical_store_address.countryId,
                                }
                              : null,
                          },
                        ],
                      })
                    )
                      .then((res) => {
                        setLoading(false);
                        if (res.type === "createAddress/fulfilled") {
                          dispatch(getOrganisationDetails(companyId));
                          next();
                        }
                        setSubmitting(false);
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        console.error(error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="border-b border-secondary_grey sm:space-y-3 space-y-2 pb-3">
                          <p className="sm:text-base text-sm">
                            2. Business operations address
                          </p>
                          <div className="sm:text-sm text-xs">
                            <p>
                              A physical location that you are conducting
                              business activities from.
                            </p>
                            <p>
                              You won't be able to use Sleek's registered
                              address as your business address.
                            </p>
                          </div>
                          <Link
                            target="_blank"
                            to={
                              "https://www.acra.gov.sg/how-to-guides/setting-up-a-local-company/registered-office-address"
                            }
                            className="text-theme border-b border-theme inline-block sm:text-base text-sm  sm:mb-3 mb-2"
                          >
                            Eligibility Criteria
                          </Link>
                          <div>
                            <ReactSelect
                              className={"p-0 sm:text-base text-sm"}
                              id="address_type"
                              placeholder="Address Type"
                              options={[
                                { value: "residental", label: "Residential" },
                                { value: "commercial", label: "Commercial" },
                              ]}
                              isLoading={false}
                              onChange={(selectedOption) => {
                                setFieldValue("address_type", selectedOption);
                              }}
                              value={values.address_type}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  border: `1px solid ${
                                    errors.address_type && touched.address_type
                                      ? "red"
                                      : "#cdddeb"
                                  }`,
                                  borderRadius: "0.25rem",
                                  padding: "6px 4px",
                                  boxShadow: "none",
                                  "&:hover": {
                                    border: "1px solid #cdddeb",
                                  },
                                }),
                              }}
                            />
                            {errors.address_type && touched.address_type ? (
                              <InputError errorTitle={errors.address_type} />
                            ) : null}
                          </div>
                          <InputWithLabel
                            label={"Address Line 1"}
                            type={"text"}
                            className={
                              errors.address_line_1 && touched.address_line_1
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="address_line_1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address_line_1}
                            errors={
                              errors.address_line_1 && touched.address_line_1
                            }
                            errorsText={errors.address_line_1}
                          />
                          <InputWithLabel
                            label={"Address Line 2"}
                            type={"text"}
                            className="border"
                            idFromName="address_line_2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address_line_2}
                          />
                          <div className="grid grid-cols-2 gap-5">
                            <InputWithLabel
                              label={"Postal Code"}
                              type="number"
                              idFromName={"postal_code"}
                              className={
                                errors.postal_code && touched.postal_code
                                  ? ` input-error `
                                  : ` border `
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postal_code}
                              errors={errors.postal_code && touched.postal_code}
                              errorsText={errors.postal_code}
                            />
                            <div>
                              <ReactSelect
                                className={
                                  "text-black sm:text-base text-sm p-0"
                                }
                                id="countryId"
                                placeholder="Country"
                                options={countryArray}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                value={
                                  countryArray?.length > 0 &&
                                  countryArray.find(
                                    (op) => op.id === values.countryId
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue("country", e);
                                  setFieldValue("countryId", e?.id);
                                }}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border: `1px solid ${
                                      errors.countryId && touched.countryId
                                        ? "red"
                                        : "#cdddeb"
                                    }`,
                                    borderRadius: "0.25rem",
                                    padding: "6px 4px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #cdddeb",
                                    },
                                  }),
                                }}
                              />

                              {errors.countryId && touched.countryId ? (
                                <>
                                  <InputError errorTitle={errors.countryId} />
                                </>
                              ) : null}
                            </div>
                          </div>
                          <p className="sm:text-sm text-xs">
                            Do you operate a physical store?
                          </p>
                          <RadioButton
                            label={"No"}
                            type="radio"
                            id="physical_no"
                            name="physical"
                            value={false}
                            checked={values.physical === false}
                            onChange={() => setFieldValue("physical", false)}
                            onBlur={handleBlur}
                            className={"sm:text-base text-sm"}
                          />
                          <RadioButton
                            label={"Yes"}
                            type="radio"
                            id="physical_yes"
                            name="physical"
                            value={true}
                            checked={values.physical === true}
                            onChange={() => setFieldValue("physical", true)}
                            onBlur={handleBlur}
                            className={"sm:text-base text-sm"}
                          />
                          {values.physical === true && (
                            <div className="space-y-3 px-5">
                              <div>
                                <CheckBox
                                  idFrom={"store_address"}
                                  label={
                                    "My store address is the same as the above"
                                  }
                                  labelClass={"sm:text-base text-sm"}
                                  name={"store_address"}
                                  className={""}
                                  value={values.store_address}
                                  checked={values.store_address}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setFieldValue("store_address", checked);

                                    if (checked) {
                                      setFieldValue(
                                        "physical_store_address.address_line_1",
                                        values.address_line_1
                                      );
                                      setFieldValue(
                                        "physical_store_address.address_line_2",
                                        values.address_line_2
                                      );
                                      setFieldValue(
                                        "physical_store_address.postal_code",
                                        values.postal_code
                                      );
                                      setFieldValue(
                                        "physical_store_address.countryId",
                                        values.countryId
                                      );
                                    } else {
                                      setFieldValue(
                                        "physical_store_address.address_line_1",
                                        ""
                                      );
                                      setFieldValue(
                                        "physical_store_address.address_line_2",
                                        ""
                                      );
                                      setFieldValue(
                                        "physical_store_address.postal_code",
                                        ""
                                      );
                                      setFieldValue(
                                        "physical_store_address.countryId",
                                        null
                                      );
                                    }
                                  }}
                                />
                                {errors.store_address &&
                                touched.store_address ? (
                                  <InputError
                                    errorTitle={errors.store_address}
                                  />
                                ) : null}
                              </div>
                              <InputWithLabel
                                label={"Address Line 1"}
                                type={"text"}
                                className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                    ${
                                      values.store_address
                                        ? errors.address_line_1
                                          ? "input-error"
                                          : "border"
                                        : errors.physical_store_address
                                            ?.address_line_1 &&
                                          touched.physical_store_address
                                            ?.address_line_1
                                        ? "input-error"
                                        : "border"
                                    }
                                    `}
                                idFromName="physical_store_address.address_line_1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.store_address
                                    ? values.address_line_1
                                    : values.physical_store_address
                                        ?.address_line_1
                                }
                                errors={
                                  values.store_address
                                    ? errors.address_line_1
                                    : errors.physical_store_address
                                        ?.address_line_1 &&
                                      touched.physical_store_address
                                        ?.address_line_1
                                }
                                errorsText={
                                  values.store_address
                                    ? errors.address_line_1
                                    : errors.physical_store_address
                                        ?.address_line_1
                                }
                                disabled={values.store_address}
                              />
                              <InputWithLabel
                                label={"Address Line 2"}
                                type={"text"}
                                className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                     ${
                                       errors.physical_store_address
                                         ?.address_line_2 &&
                                       touched.physical_store_address
                                         ?.address_line_2
                                         ? "input-error"
                                         : "border"
                                     }
                                    `}
                                idFromName="physical_store_address.address_line_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.store_address
                                    ? values.address_line_2
                                    : values.physical_store_address
                                        ?.address_line_2
                                }
                                errors={
                                  errors.physical_store_address
                                    ?.address_line_2 &&
                                  touched.physical_store_address?.address_line_2
                                }
                                errorsText={
                                  errors.physical_store_address?.address_line_2
                                }
                                disabled={values.store_address}
                              />
                              <div className="grid grid-cols-2 gap-5">
                                <InputWithLabel
                                  label={"Postal Code"}
                                  type="number"
                                  idFromName="physical_store_address.postal_code"
                                  className={`
                                      ${
                                        values.store_address
                                          ? "text-gray-500"
                                          : ""
                                      } 
                                      ${
                                        values.store_address
                                          ? errors.postal_code
                                            ? "input-error"
                                            : "border"
                                          : errors.physical_store_address
                                              ?.postal_code &&
                                            touched.physical_store_address
                                              ?.postal_code
                                          ? "input-error"
                                          : "border"
                                      }
                                      `}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.store_address
                                      ? values.postal_code
                                      : values.physical_store_address
                                          ?.postal_code
                                  }
                                  errors={
                                    values.store_address
                                      ? errors.postal_code
                                      : errors.physical_store_address
                                          ?.postal_code &&
                                        touched.physical_store_address
                                          ?.postal_code
                                  }
                                  errorsText={
                                    values.store_address
                                      ? errors.postal_code
                                      : errors.physical_store_address
                                          ?.postal_code
                                  }
                                  disabled={values.store_address}
                                />
                                <div>
                                  <ReactSelect
                                    className={
                                      "text-black sm:text-base text-sm p-0"
                                    }
                                    id="physical_store_country"
                                    placeholder="Country"
                                    options={countryArray}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    isDisabled={values.store_address}
                                    value={countryArray.find((op) =>
                                      values.store_address
                                        ? op.id === values.countryId
                                        : op.id ===
                                          values.physical_store_address
                                            ?.countryId
                                    )}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "physical_store_address.countryId",
                                        e?.id
                                      )
                                    }
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        border: `1px solid ${
                                          values.store_address
                                            ? errors.countryId
                                              ? "red"
                                              : "#cdddeb"
                                            : errors.physical_store_address
                                                ?.countryId &&
                                              touched.physical_store_address
                                                ?.countryId
                                            ? "red"
                                            : "#cdddeb"
                                        }`,
                                        borderRadius: "0.25rem",
                                        padding: "6px 4px",
                                        boxShadow: "none",
                                        "&:hover": {
                                          border: "1px solid #cdddeb",
                                        },
                                      }),
                                    }}
                                  />
                                  {values.store_address ? (
                                    errors.countryId ? (
                                      <InputError
                                        errorTitle={errors.countryId}
                                      />
                                    ) : null
                                  ) : errors.physical_store_address
                                      ?.countryId &&
                                    touched.physical_store_address
                                      ?.countryId ? (
                                    <InputError
                                      errorTitle={
                                        errors.physical_store_address.countryId
                                      }
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end gap-4 pt-3">
                          <button
                            type="button"
                            className="btn-outline flex items-center space-x-3 uppercase"
                            onClick={() => prev()}
                          >
                            <BackArrow className="fill-theme" fillOpacity={1} />
                            <span>Back</span>
                          </button>
                          <button
                            type="submit"
                            className="btn-theme flex items-center space-x-3 uppercase"
                            disabled={loading}
                          >
                            {loading ? (
                              <ButtonLoader />
                            ) : (
                              <span>Save & Proceed On</span>
                            )}
                            <img className="w-5 h-5" src={goOn} />
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}

          <p className="border mt-3" />
          {selectedOption === "Company Address" && (
            <>
              <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    address_type: addressTypeValue ?? null,
                    address_line_1:
                      addresses?.length && addresses[0]?.address?.address_line_1
                        ? addresses[0]?.address?.address_line_1
                        : "",
                    address_line_2:
                      addresses?.length && addresses[0]?.address?.address_line_2
                        ? addresses[0]?.address?.address_line_2
                        : "",
                    postal_code:
                      addresses?.length && addresses[0]?.address?.postal_code
                        ? addresses[0]?.address?.postal_code
                        : "",
                    countryId:
                      addresses?.length && addresses[0]?.address?.country_id
                        ? addresses[0]?.address?.country_id
                        : "",
                    same_as_above: false,
                    operations_address_type: operationsAddressTypeValue
                      ? operationsAddressTypeValue
                      : null,
                    operations_address_line_1:
                      addresses?.length && addresses[1]?.address?.address_line_1
                        ? addresses[1]?.address?.address_line_1
                        : "",
                    operations_address_line_2:
                      addresses?.length && addresses[1]?.address?.address_line_2
                        ? addresses[1]?.address?.address_line_2
                        : "",
                    operations_postal_code:
                      addresses?.length && addresses[1]?.address?.postal_code
                        ? addresses[1]?.address?.postal_code
                        : "",
                    operations_countryId:
                      addresses?.length && addresses[1]?.address?.country_id
                        ? addresses[1]?.address?.country_id
                        : "",
                    physical: false,
                    store_address: false,
                    physical_store_address: {
                      same_as_above: false,
                      address_line_1:
                        addresses?.length &&
                        addresses[1]?.physical_store_address?.address_line_1
                          ? addresses[1]?.physical_store_address?.address_line_1
                          : "",
                      address_line_2:
                        addresses?.length &&
                        addresses[1]?.physical_store_address?.address_line_2
                          ? addresses[1]?.physical_store_address?.address_line_2
                          : "",
                      postal_code:
                        addresses?.length &&
                        addresses[1]?.physical_store_address?.address_line_2
                          ? addresses[1]?.physical_store_address?.address_line_2
                          : "",
                      countryId:
                        addresses?.length &&
                        addresses[1]?.physical_store_address?.country_id
                          ? addresses[1]?.physical_store_address?.country_id
                          : "",
                    },
                  }}
                  validationSchema={Yup.object().shape({
                    address_type: Yup.object()
                      .shape({
                        value: Yup.string().required(
                          "Please Select Address Type"
                        ),
                        label: Yup.string().required(
                          "Please Select Address Type"
                        ),
                      })
                      .nullable()
                      .required("Please Select Address Type"),

                    address_line_1: Yup.string().required(
                      "Please Enter Address Line 1"
                    ),

                    address_line_2: Yup.string(),

                    postal_code: Yup.string()
                      .required("Please Enter Postal Code")
                      .length(5, "Postal Code must be exactly 5 characters")
                      .matches(/^\d+$/, "Postal Code must be numeric"),

                    countryId: Yup.string()
                      .nullable()
                      .required("Please Select Country"),

                    same_as_above: Yup.boolean(),

                    operations_address_type: Yup.object()
                      .shape({
                        value: Yup.string().required(
                          "Please Select Address Type"
                        ),
                        label: Yup.string().required(
                          "Please Select Address Type"
                        ),
                      })
                      .nullable()
                      .when("same_as_above", {
                        is: false,
                        then: Yup.object()
                          .shape({
                            value: Yup.string().required(
                              "Please Select Address Type"
                            ),
                            label: Yup.string().required(
                              "Please Select Address Type"
                            ),
                          })
                          .nullable()
                          .required("Please Select Address Type"),
                        otherwise: Yup.object().nullable(),
                      }),

                    operations_address_line_1: Yup.string().when(
                      "same_as_above",
                      {
                        is: false,
                        then: Yup.string()
                          .nullable()
                          .required("Please Enter Operations Address Line 1"),
                        otherwise: Yup.string().nullable(),
                      }
                    ),

                    operations_address_line_2: Yup.string().when(
                      "same_as_above",
                      {
                        is: false,
                        then: Yup.string(),
                        otherwise: Yup.string(),
                      }
                    ),

                    operations_postal_code: Yup.string().when("same_as_above", {
                      is: false,
                      then: Yup.string()
                        .nullable()
                        .required("Please Enter Operations Postal Code")
                        .length(
                          5,
                          "Operations Postal Code must be exactly 5 characters"
                        )
                        .matches(
                          /^\d+$/,
                          "Operations Postal Code must be numeric"
                        ),
                      otherwise: Yup.string().nullable(),
                    }),

                    operations_countryId: Yup.string().when("same_as_above", {
                      is: false,
                      then: Yup.string()
                        .nullable()
                        .required("Please Select Operations Country"),
                      otherwise: Yup.string().nullable(),
                    }),

                    store_address: Yup.boolean(),

                    physical: Yup.boolean()
                      .required("Please Select Do you operate a physical store")
                      .oneOf(
                        [true, false],
                        "Please Select Do you operate a physical store"
                      ),

                    physical_store_address: Yup.object().when("physical", {
                      is: true,
                      then: Yup.object().shape({
                        same_as_above: Yup.boolean(),
                        address_line_1: Yup.string().when("same_as_above", {
                          is: false,
                          then: Yup.string().required(
                            "Please Enter Physical Store Address Line 1"
                          ),
                          otherwise: Yup.string(),
                        }),
                        address_line_2: Yup.string().when("same_as_above", {
                          is: false,
                          then: Yup.string(),
                          otherwise: Yup.string(),
                        }),
                        postal_code: Yup.string().when("same_as_above", {
                          is: false,
                          then: Yup.string()
                            .required("Please Enter Physical Store Postal Code")
                            .length(
                              5,
                              "Physical Store Postal Code must be exactly 5 characters"
                            )
                            .matches(
                              /^\d+$/,
                              "Physical Store Postal Code must be numeric"
                            ),
                          otherwise: Yup.string(),
                        }),
                        countryId: Yup.string().when("same_as_above", {
                          is: false,
                          then: Yup.string()
                            .nullable()
                            .required("Please Select Physical Store Country"),
                          otherwise: Yup.string().nullable(),
                        }),
                      }),
                      otherwise: Yup.object().notRequired(),
                    }),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    let type;
                    if (selectedOption === "Registered Address") {
                      type = "registered";
                    } else if (selectedOption === "Company Address") {
                      type = "own";
                    } else {
                      type = "default";
                    }

                    dispatch(
                      createAddress({
                        company_id: companyId,
                        type,
                        details: [
                          {
                            sub_type: "office_address",
                            address_type:
                              values.address_type?.value || "residental",
                            address_line_1: values.address_line_1,
                            address_line_2: values.address_line_2 || "",
                            postal_code: values.postal_code,
                            country_id: values.countryId,
                          },
                          {
                            sub_type: "business_operational",
                            same_as_above: values.same_as_above || false,
                            address_type:
                              values.operations_address_type?.value ||
                              "residental",
                            address_line_1: values.same_as_above
                              ? values.address_line_1
                              : values.operations_address_line_1,
                            address_line_2: values.same_as_above
                              ? values.address_line_2
                              : values.operations_address_line_2 || "",
                            postal_code: values.same_as_above
                              ? values.postal_code
                              : values.operations_postal_code,
                            country_id: values.same_as_above
                              ? values.countryId
                              : values.operations_countryId,
                            physical_store: values.physical || false,
                            physical_store_address: values.physical
                              ? {
                                  same_as_above: values.store_address || false,
                                  address_line_1: values.store_address
                                    ? values.operations_address_line_1
                                    : values.physical_store_address
                                        .address_line_1 || "",
                                  address_line_2: values.store_address
                                    ? values.operations_address_line_2
                                    : values.physical_store_address
                                        .address_line_2 || "",
                                  postal_code: values.store_address
                                    ? values.operations_postal_code
                                    : values.physical_store_address.postal_code,
                                  country_id: values.store_address
                                    ? values.operations_countryId
                                    : values.physical_store_address.countryId,
                                }
                              : null,
                          },
                        ],
                      })
                    )
                      .then((res) => {
                        setLoading(false);
                        if (res.type === "createAddress/fulfilled") {
                          next();
                        }
                        setSubmitting(false);
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        console.error(error || error.message);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} className="space-y-3 pt-3">
                      <div className="border-b border-secondary_grey space-y-3 pb-3">
                        <p className="lg:text-xl sm:text-base text-sm mb-3">
                          1. Registered Office Address
                        </p>
                        <div>
                          <ReactSelect
                            className={"sm:text-base text-sm p-0"}
                            id="address_type"
                            placeholder="Address Type"
                            options={[
                              { value: "residental", label: "Residential" },
                              { value: "commercial", label: "Commercial" },
                            ]}
                            isLoading={false}
                            onChange={(e) => setFieldValue("address_type", e)}
                            styles={{
                              control: (base) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.address_type && touched.address_type
                                    ? "red"
                                    : "#cdddeb"
                                }`,
                                borderRadius: "0.25rem",
                                padding: "6px 4px",
                                boxShadow: "none",
                                "&:hover": {
                                  border: "1px solid #cdddeb",
                                },
                              }),
                            }}
                          />
                          {errors.address_type && touched.address_type ? (
                            <InputError errorTitle={errors.address_type} />
                          ) : null}
                        </div>
                        <InputWithLabel
                          label={"Address Line 1"}
                          type={"text"}
                          className={
                            errors.address_line_1 && touched.address_line_1
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="address_line_1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address_line_1}
                          errors={
                            errors.address_line_1 && touched.address_line_1
                          }
                          errorsText={errors.address_line_1}
                        />
                        <InputWithLabel
                          label={"Address Line 2"}
                          type={"text"}
                          className="border"
                          idFromName="address_line_2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address_line_2}
                        />
                        <div className="grid grid-cols-2 gap-5">
                          <InputWithLabel
                            label={"Postal Code"}
                            type="number"
                            idFromName={"postal_code"}
                            className={
                              errors.postal_code && touched.postal_code
                                ? ` input-error `
                                : ` border `
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postal_code}
                            errors={errors.postal_code && touched.postal_code}
                            errorsText={errors.postal_code}
                          />
                          <div>
                            <ReactSelect
                              className={"text-black sm:text-base text-sm p-0"}
                              id="countryId"
                              placeholder="Country"
                              options={countryArray}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              value={
                                countryArray?.length > 0 &&
                                countryArray.find(
                                  (op) => op.id === values.countryId
                                )
                              }
                              onChange={(e) => {
                                setFieldValue("country", e);
                                setFieldValue("countryId", e?.id);
                              }}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  border: `1px solid ${
                                    errors.countryId && touched.countryId
                                      ? "red"
                                      : "#cdddeb"
                                  }`,
                                  borderRadius: "0.25rem",
                                  padding: "6px 4px",
                                  boxShadow: "none",
                                  "&:hover": {
                                    border: "1px solid #cdddeb",
                                  },
                                }),
                              }}
                            />
                            {errors.countryId && touched.countryId ? (
                              <InputError errorTitle={errors.countryId} />
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <CheckBox
                            idFrom={"same_as_above"}
                            label={
                              "This is also my business operations address"
                            }
                            labelClass={"sm:text-base text-sm"}
                            name={"same_as_above"}
                            className={""}
                            value={values.same_as_above}
                            checked={values.same_as_above}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              setFieldValue("same_as_above", checked);
                              setIsSameAddress(checked);

                              if (checked) {
                                setFieldValue(
                                  "operations_address_line_1",
                                  values.address_line_1
                                );
                                setFieldValue(
                                  "operations_address_line_2",
                                  values.address_line_2
                                );
                                setFieldValue(
                                  "operations_postal_code",
                                  values.postal_code
                                );
                                setFieldValue(
                                  "operations_countryId",
                                  values.countryId
                                );
                                setFieldValue(
                                  "operations_address_type",
                                  values.address_type
                                );
                              } else {
                                setFieldValue("operations_address_line_1", "");
                                setFieldValue("operations_address_line_2", "");
                                setFieldValue("operations_postal_code", "");
                                setFieldValue("operations_countryId", "");
                                setFieldValue("operations_address_type", null);
                              }
                            }}
                          />
                          {errors.same_as_above && touched.same_as_above ? (
                            <InputError errorTitle={errors.same_as_above} />
                          ) : null}
                        </div>
                      </div>

                      <div className="border-b border-secondary_grey space-y-3 pb-3">
                        <p className="lg:text-xl  sm:text-base text-sm mb-3">
                          2. Business operations address
                        </p>
                        <div className="sm:text-sm text-xs">
                          <p>
                            A physical location that you are conducting business
                            activities from.
                          </p>
                          <p>
                            You won't be able to use Sleek's registered address
                            as your business address.
                          </p>
                        </div>
                        <button
                          type="button"
                          className="text-theme border-b sm:text-base text-sm border-theme"
                        >
                          Eligibility Criteria
                        </button>
                        <div className="space-y-3">
                          <ReactSelect
                            className={"sm:text-base text-sm p-0"}
                            id="operations_address_type"
                            placeholder="Address Type"
                            options={[
                              { value: "residental", label: "Residential" },
                              { value: "commercial", label: "Commercial" },
                            ]}
                            isDisabled={values.same_as_above}
                            isLoading={false}
                            onChange={(e) =>
                              isSameAddress
                                ? setFieldValue("address_type", e)
                                : setFieldValue("operations_address_type", e)
                            }
                            value={
                              isSameAddress
                                ? values.address_type
                                : values.operations_address_type
                            }
                            styles={{
                              control: (base) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.operations_address_type &&
                                  touched.operations_address_type
                                    ? "red"
                                    : "#cdddeb"
                                }`,
                                borderRadius: "0.25rem",
                                padding: "6px 4px",
                                boxShadow: "none",
                                "&:hover": {
                                  border: "1px solid #cdddeb",
                                },
                              }),
                            }}
                          />
                          {errors.operations_address_type &&
                          touched.operations_address_type ? (
                            <InputError
                              errorTitle={errors.operations_address_type}
                            />
                          ) : null}
                          {values?.operations_address_type?.value?.length && (
                            <div className="space-y-3">
                              <InputWithLabel
                                label={"Address Line 1"}
                                disabled={values.same_as_above}
                                type={"text"}
                                className={`
                                   ${
                                     values.same_as_above ? "text-gray-500" : ""
                                   } 
                                   ${
                                     isSameAddress
                                       ? errors.address_line_1
                                         ? "input-error"
                                         : "border"
                                       : errors?.address_line_1 &&
                                         touched?.address_line_1
                                       ? "input-error"
                                       : "border"
                                   }
                                  `}
                                idFromName="operations_address_line_1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  isSameAddress
                                    ? values.address_line_1
                                    : values.operations_address_line_1
                                }
                                errors={
                                  isSameAddress
                                    ? errors.address_line_1
                                    : errors.operations_address_line_1 &&
                                      touched.operations_address_line_1
                                }
                                errorsText={
                                  isSameAddress
                                    ? errors.address_line_1
                                    : errors.operations_address_line_1
                                }
                              />
                              <InputWithLabel
                                label={"Address Line 2"}
                                type={"text"}
                                disabled={values.same_as_above}
                                className={`
                                ${values.same_as_above ? "text-gray-500" : ""} 
                                ${
                                  errors.address_line_2 &&
                                  touched.address_line_2
                                    ? ` input-error `
                                    : ` border `
                                }
                                `}
                                idFromName="operations_address_line_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  isSameAddress
                                    ? values.address_line_2
                                    : values.operations_address_line_2
                                }
                                errors={
                                  errors.operations_address_line_2 &&
                                  touched.operations_address_line_2
                                }
                                errorsText={errors.operations_address_line_2}
                              />
                              <div className="grid grid-cols-2 gap-5">
                                <InputWithLabel
                                  label={"Postal Code"}
                                  type="number"
                                  disabled={values.same_as_above}
                                  idFromName={"operations_postal_code"}
                                  className={`
                                  ${
                                    values.same_as_above ? "text-gray-500" : ""
                                  } 
                                  ${
                                    isSameAddress
                                      ? errors.postal_code
                                        ? "input-error"
                                        : "border"
                                      : errors?.operations_postal_code &&
                                        touched?.operations_postal_code
                                      ? "input-error"
                                      : "border"
                                  }
                                  `}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    isSameAddress
                                      ? values.postal_code
                                      : values.operations_postal_code
                                  }
                                  errors={
                                    isSameAddress
                                      ? errors.postal_code
                                      : errors.operations_postal_code &&
                                        touched.operations_postal_code
                                  }
                                  errorsText={
                                    isSameAddress
                                      ? errors.postal_code
                                      : errors.operations_postal_code
                                  }
                                />
                                <div>
                                  <ReactSelect
                                    className={
                                      "text-black sm:text-base text-sm p-0"
                                    }
                                    id="operations_countryId"
                                    isDisabled={values.same_as_above}
                                    placeholder="Country"
                                    options={countryArray}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    value={countryArray.find((op) =>
                                      isSameAddress
                                        ? op.id === values.countryId
                                        : op.id === values.operations_countryId
                                    )}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "operations_countryId",
                                        e?.id
                                      );
                                    }}
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        border: `1px solid ${
                                          errors.countryId && touched.countryId
                                            ? "red"
                                            : "#cdddeb"
                                        }`,
                                        borderRadius: "0.25rem",
                                        padding: "6px 4px",
                                        boxShadow: "none",
                                        "&:hover": {
                                          border: "1px solid #cdddeb",
                                        },
                                      }),
                                    }}
                                  />
                                  {isSameAddress ? (
                                    errors.countryId ? (
                                      <InputError
                                        errorTitle={errors.countryId}
                                      />
                                    ) : null
                                  ) : errors.operations_countryId &&
                                    touched.operations_countryId ? (
                                    <InputError
                                      errorTitle={errors.operations_countryId}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <p className="sm:text-sm text-xs">
                                Do you operate a physical store?
                              </p>
                              <RadioButton
                                label={"No"}
                                type="radio"
                                id="physical_no"
                                name="physical"
                                value={false}
                                checked={values.physical === false}
                                onChange={() =>
                                  setFieldValue("physical", false)
                                }
                                onBlur={handleBlur}
                                className={"sm:text-base text-sm"}
                              />
                              <RadioButton
                                label={"Yes"}
                                type="radio"
                                id="physical_yes"
                                name="physical"
                                value={true}
                                checked={values.physical === true}
                                onChange={() => setFieldValue("physical", true)}
                                onBlur={handleBlur}
                                className={"sm:text-base text-sm"}
                              />
                              {values.physical === true && (
                                <div className="space-y-3 px-6">
                                  <div>
                                    <CheckBox
                                      idFrom={"store_address"}
                                      label={
                                        "My store address is the same as the above"
                                      }
                                      labelClass={"sm:text-base text-sm"}
                                      name={"store_address"}
                                      checked={values.store_address}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "store_address",
                                          e.target.checked
                                        );
                                        if (e.target.checked) {
                                          setFieldValue(
                                            "physical_store_address.address_line_1",
                                            values.operations_address_line_1
                                          );
                                          setFieldValue(
                                            "physical_store_address.address_line_2",
                                            values.operations_address_line_2
                                          );
                                          setFieldValue(
                                            "physical_store_address.postal_code",
                                            values.operations_postal_code
                                          );
                                          setFieldValue(
                                            "physical_store_address.countryId",
                                            values.operations_countryId
                                          );
                                        } else {
                                          setFieldValue(
                                            "physical_store_address.address_line_1",
                                            ""
                                          );
                                          setFieldValue(
                                            "physical_store_address.address_line_2",
                                            ""
                                          );
                                          setFieldValue(
                                            "physical_store_address.postal_code",
                                            ""
                                          );
                                          setFieldValue(
                                            "physical_store_address.countryId",
                                            null
                                          );
                                        }
                                      }}
                                      className={""}
                                    />
                                    {errors.store_address &&
                                    touched.store_address ? (
                                      <InputError
                                        errorTitle={errors.store_address}
                                      />
                                    ) : null}
                                  </div>
                                  <InputWithLabel
                                    label={"Address Line 1"}
                                    type={"text"}
                                    className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                      ${
                                        values.store_address
                                          ? errors.operations_address_line_1
                                            ? "input-error"
                                            : "border"
                                          : errors.physical_store_address
                                              ?.address_line_1 &&
                                            touched.physical_store_address
                                              ?.address_line_1
                                          ? "input-error"
                                          : "border"
                                      }
                                    `}
                                    idFromName="physical_store_address.address_line_1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.store_address
                                        ? isSameAddress
                                          ? values.address_line_1
                                          : values.operations_address_line_1
                                        : values.physical_store_address
                                            ?.address_line_1
                                    }
                                    errors={
                                      values.store_address
                                        ? errors.operations_address_line_1
                                        : errors.physical_store_address
                                            ?.address_line_1 &&
                                          touched.physical_store_address
                                            ?.address_line_1
                                    }
                                    errorsText={
                                      values.store_address
                                        ? errors.address_line_1
                                        : errors.physical_store_address
                                            ?.address_line_1
                                    }
                                    disabled={values.store_address}
                                  />
                                  <InputWithLabel
                                    label={"Address Line 2"}
                                    type={"text"}
                                    className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                     ${
                                       errors.physical_store_address
                                         ?.address_line_2 &&
                                       touched.physical_store_address
                                         ?.address_line_2
                                         ? "input-error"
                                         : "border"
                                     }
                                    `}
                                    idFromName="physical_store_address.address_line_2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.store_address
                                        ? isSameAddress
                                          ? values.address_line_2
                                          : values.operations_address_line_2
                                        : values.physical_store_address
                                            ?.address_line_2
                                    }
                                    errors={
                                      errors.physical_store_address
                                        ?.address_line_2 &&
                                      touched.physical_store_address
                                        ?.address_line_2
                                    }
                                    errorsText={
                                      errors.physical_store_address
                                        ?.address_line_2
                                    }
                                    disabled={values.store_address}
                                  />
                                  <div className="grid grid-cols-2 gap-5">
                                    <InputWithLabel
                                      label={"Postal Code"}
                                      type="number"
                                      idFromName="physical_store_address.postal_code"
                                      className={`
                                      ${
                                        values.store_address
                                          ? "text-gray-500"
                                          : ""
                                      } 
                                        ${
                                          values.store_address
                                            ? errors.operations_postal_code
                                              ? "input-error"
                                              : "border"
                                            : errors.physical_store_address
                                                ?.postal_code &&
                                              touched.physical_store_address
                                                ?.postal_code
                                            ? "input-error"
                                            : "border"
                                        }

                                      `}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={
                                        values.store_address
                                          ? isSameAddress
                                            ? values.postal_code
                                            : values.operations_postal_code
                                          : values.physical_store_address
                                              ?.postal_code
                                      }
                                      errors={
                                        values.store_address
                                          ? errors.operations_postal_code
                                          : errors.physical_store_address
                                              ?.postal_code &&
                                            touched.physical_store_address
                                              ?.postal_code
                                      }
                                      errorsText={
                                        values.store_address
                                          ? errors.operations_postal_code
                                          : errors.physical_store_address
                                              ?.postal_code
                                      }
                                      disabled={values.store_address}
                                    />
                                    <div>
                                      <ReactSelect
                                        className={
                                          "text-black sm:text-base text-sm p-0"
                                        }
                                        id="physical_store_country"
                                        placeholder="Country"
                                        options={countryArray}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        isDisabled={values.store_address}
                                        value={countryArray.find((op) => {
                                          if (values.store_address) {
                                            return isSameAddress
                                              ? op.id === values.countryId
                                              : op.id ===
                                                  values.operations_countryId;
                                          }
                                          return (
                                            op.id ===
                                            values.physical_store_address
                                              ?.countryId
                                          );
                                        })}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "physical_store_address.countryId",
                                            e?.id
                                          )
                                        }
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            border: `1px solid ${
                                              errors.physical_store_address
                                                ?.countryId &&
                                              touched.physical_store_address
                                                ?.countryId
                                                ? "red"
                                                : "#cdddeb"
                                            }`,
                                            borderRadius: "0.25rem",
                                            padding: "6px 4px",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: "1px solid #cdddeb",
                                            },
                                          }),
                                        }}
                                      />
                                      {errors.physical_store_address
                                        ?.countryId &&
                                      touched.physical_store_address
                                        ?.countryId ? (
                                        <InputError
                                          errorTitle={
                                            errors.physical_store_address
                                              ?.countryId
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-end gap-4 pt-3">
                        <button
                          type="button"
                          className="btn-outline flex items-center space-x-3 uppercase"
                          onClick={() => prev()}
                        >
                          <BackArrow className="fill-theme" fillOpacity={1} />
                          <span>Back</span>
                        </button>
                        <button
                          type="submit"
                          className="btn-theme flex items-center space-x-3 uppercase"
                          disabled={loading}
                        >
                          {loading ? (
                            <ButtonLoader />
                          ) : (
                            <span>Save & Proceed On</span>
                          )}
                          <img className="w-5 h-5" src={goOn} />
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyAddress;
