import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CheckBox, InputWithLabel } from "../../../components/micro";
import ConfirmationModel from "../../../commons/ConfirmationModel";

const AddNewCard = ({ onClose }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [cardValues, setCardValues] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);

  const handleSave = (values) => {
    console.log("values", values);
    setIsLoad(true);
  };
  return (
    <>
      <div className="model" onClick={onClose}>
        <div
          className="bg-white drop-shadow-lg rounded-lg w-full max-w-2xl max-h-[700px] overflow-y-auto min-h-36 py-5 px-6 m-5 relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="sm:text-2xl text-base font-ebGaramond border-b border-secondary_grey capitalize w-full pb-3 mb-4">
            Add new card
          </h2>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
              number: "",
              expiry_month: "",
              expiry_year: "",
              security_code: "",
              default_card: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Please Enter Card Name"),
              number: Yup.string().required("Please Enter Card Number"),
              expiry_month: Yup.string().required("Please Enter Expiry Month"),
              expiry_year: Yup.string().required("Please Enter Expiry Year"),
              security_code: Yup.string().required(
                "Please Enter Security Code"
              ),
              default_card: Yup.boolean(),
            })}
            onSubmit={(values) => {
              setCardValues(values);
              setIsConfirm(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Form className="w-full" onSubmit={handleSubmit}>
                <div className="space-y-3 mb-6">
                  <InputWithLabel
                    label={"Name on Card*"}
                    type={"text"}
                    className={
                      errors.name && touched.name ? ` input-error ` : ` border `
                    }
                    idFromName="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    errors={errors.name && touched.name}
                    errorsText={errors.name}
                  />
                  <InputWithLabel
                    label={"Card Number*"}
                    type={"number"}
                    className={
                      errors.number && touched.number
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.number}
                    errors={errors.number && touched.number}
                    errorsText={errors.number}
                  />
                  <div className="grid grid-cols-3 gap-3">
                    <InputWithLabel
                      label={"Expiry Month*"}
                      type={"text"}
                      className={
                        errors.expiry_month && touched.expiry_month
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="expiry_month"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiry_month}
                      errors={errors.expiry_month && touched.expiry_month}
                      errorsText={errors.expiry_month}
                    />
                    <InputWithLabel
                      label={"Expiry Year*"}
                      type={"text"}
                      className={
                        errors.expiry_year && touched.expiry_year
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="expiry_year"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiry_year}
                      errors={errors.expiry_year && touched.expiry_year}
                      errorsText={errors.expiry_year}
                    />
                    <InputWithLabel
                      label={"Security Code*"}
                      type={"text"}
                      className={
                        errors.security_code && touched.security_code
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="security_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.security_code}
                      errors={errors.security_code && touched.security_code}
                      errorsText={errors.security_code}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="w-full">
                    <CheckBox
                      idFrom={"default_card"}
                      label={"Saved as default Card"}
                      name={"default_card"}
                      value={values.default_card}
                      checked={values.default_card}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={""}
                    />
                  </div>
                  <div className="flex justify-end gap-4 w-full">
                    <button
                      type="button"
                      className="btn-outline uppercase"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isLoad}
                      className={`${
                        isLoad ? "btn-outline-disabled" : "btn-theme"
                      } uppercase`}
                    >
                      {isLoad ? "Loading" : "Save"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isConfirm && (
        <ConfirmationModel
          isLoading={isLoad}
          onClose={() => setIsConfirm(false)}
          onConfirm={() => handleSave(cardValues)}
          confirmText={"Save My Payment Method?"}
        />
      )}
    </>
  );
};

export default AddNewCard;
