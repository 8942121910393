import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CheckBox, TextareaWithLabel } from "../../../components/micro";

const Objectives = ({
  objectivesData,
  setObjectivesSelectedArray,
  setObjectivesError,
  objectivesVales,
  objectives_other,
}) => {
  const getInitialValues = (arr, objectivesVales) => {
    let initial = {
      objectives_other: objectives_other ?? "",
    };

    arr?.forEach((element) => {
      initial[element.id] = objectivesVales?.some(
        (val) => val.id === element.id
      );
    });
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(objectivesData, objectivesVales),
  });

  useEffect(() => {
    let trueFieldKeys = Object.keys(formik.values).filter(
      (key) => formik.values[key]
    );
    // remove objectives other form
    trueFieldKeys = trueFieldKeys.filter(function (item) {
      return item !== "objectives_other";
    });

    setObjectivesSelectedArray({
      objectivesSelectedArray: trueFieldKeys,
      objectives_other: formik.values.objectives_other,
    });
    setObjectivesError("");
  }, [formik.values]);
  return (
    <>
      {objectivesData?.map((element) => {
        return (
          <CheckBox
            idFrom={element.slug}
            label={element?.title}
            name={element.id}
            value={formik.values[element.id]}
            checked={formik.values[element.id]}
            onChange={formik.handleChange}
            className={"sm:text-base text-sm"}
          />
        );
      })}
      {formik.values[1] && (
        <TextareaWithLabel
          idFromName={"objectives_other"}
          label={"Others"}
          className={"border"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.objectives_other}
        />
      )}
    </>
  );
};

export default Objectives;
