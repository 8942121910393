import React, { useEffect, useRef, useState } from "react";
import { User } from "iconsax-react";
import { Logo } from "../../assets/image";
import FileUploadChoose from "../../commons/FileUploadChoose";
import ChatModal from "./ChatImg";
import RippleLoader from "../../commons/RippleLoader";
import {
  Close,
  ImageSearchIcon,
  PDFDoc,
  SupportAgent,
} from "../../assets/svg/AllSvg";
import { InputError } from "../../commons/MicroComponents";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../redux/uploadFile/slice";
import { io } from "socket.io-client";

const ChatList = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: "Message content goes here", sender: "support" },
    { id: 2, text: "Message content goes here", sender: "user" },
  ]);
  const [documentArray, setDocumentArray] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [showChat, setShowChat] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [chatDetails, setChatDetails] = useState([]);
  const [error, setError] = useState("");
  const companyId = sessionStorage.getItem("companyID");
  const dispatch = useDispatch();
  const socketRef = useRef();
  const socketURL = process.env.REACT_APP_SOCKET_URL;

  const token = sessionStorage.getItem("accessToken");

  // console.log("chatDetails", chatDetails);

  // Start Socket
  useEffect(() => {
    socketRef.current = io(socketURL, {
      auth: {
        token: token,
      },
    });

    socketRef.current.emit("getChat", {
      chatId: companyId,
    });

    socketRef.current.on("error", (err) => {
      setError(err);
    });

    socketRef.current.on("chatList", (data) => {
      setChatDetails(data);
      // setChatDocuments(data?.data?.documents);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [companyId]);

  const handleSend = () => {
    if (newMessage.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: prevMessages.length + 1, text: newMessage, sender: "user" },
      ]);
      setNewMessage("");
    }
  };

  const openImageModal = (ind) => {
    setSelectedImageIndex(ind);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  // Get File URL Upload Editor
  const handleUploadFile = (e) => {
    setUploadError(false);
    const file = e?.target?.files[0];
    const type = file?.type;
    const formData = new FormData();
    formData.append("type", "chat");
    formData.append("file", file);
    formData.append(
      "document_type",
      type === "application/pdf" ? "doc" : "img"
    );
    setIsFileUpload(true);
    dispatch(uploadFile(formData)).then((res) => {
      setIsFileUpload(false);
      if (res.type === "uploadFile/fulfilled") {
        setDocumentArray((prevArray) => [...prevArray, res.payload.data]);
      }
    });
  };

  // Remove Upload Document
  const removeItem = (index) => {
    setDocumentArray([
      ...documentArray.slice(0, index),
      ...documentArray.slice(index + 1),
    ]);
  };

  //   Upload On Chat
  const handleUploadOnChat = () => {
    if (documentArray?.length) {
      const fillArray = documentArray?.map((e) => e.id);
      console.log("fillArray", fillArray);

      // socketRef.current.emit("sendMessage", {
      //   chat_id: newChatName?.id,
      //   sender_id: adminId,
      //   type: "media",
      //   url: fillArray,
      // });
      // getNewMessage();
    } else {
      setUploadError(true);
      return;
    }
  };

  return (
    <>
      {showChat && (
        <div className="fixed bottom-28 right-10 w-96 h-[70vh] bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col p-4">
          <div className="bg-bgBlue text-white p-4 rounded-t-2xl flex items-center">
            <img src={Logo} alt="logo" className="w-14 h-14" />
            <div className="ml-5">
              <h2 className="text-lg font-bold text-black">
                Ei Biz Live Chat Support
              </h2>
              <p className="text-black">Back Online at 9:00am</p>
            </div>
          </div>
          <div className="flex-1 p-4 overflow-y-auto">
            {messages.map((message, ind) => (
              <div
                key={message.id}
                className={`mb-4 flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {message.sender !== "user" && (
                  <div className="flex-shrink-0 mr-2 mt-1">
                    <div className="bg-gray-400 w-8 h-8 rounded-full flex items-center justify-center text-white">
                      <User className="h-5 w-5 fill-white text-white" />
                    </div>
                  </div>
                )}
                <div
                  className={`max-w-xs p-2 rounded-lg ${
                    message.sender === "user"
                      ? "bg-lightGreen text-white"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  {message.type === "image" ? (
                    <img
                      src={message.file}
                      alt="Uploaded"
                      className="w-24 h-24 object-cover rounded-lg border-2 border-gray-300"
                      onClick={() => openImageModal(ind)}
                    />
                  ) : (
                    message.text
                  )}
                </div>
                {message.sender === "user" && (
                  <div className="flex-shrink-0 ml-2 mt-1">
                    <div className="bg-bgGreen w-8 h-8 rounded-full flex items-center justify-center text-white">
                      <User className="h-5 w-5 fill-white text-white" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button className="inline-flex justify-center items-center mx-5 my-2 p-1 cursor-pointer text-black hover:bg-gray-100 dark:hover:bg-gray-200 border border-black rounded-lg">
            <p className="mr-2 font-poppins text-sm font-medium">
              CHAT WITH LIVE SUPPORT
            </p>
            <SupportAgent />
          </button>
          <div className="border-t border-gray-300">
            <form>
              <label htmlFor="chat" className="sr-only">
                Your message
              </label>
              <div className="flex items-center py-3 rounded-lg">
                <button
                  type="button"
                  className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-200"
                  onClick={() => setShowUpload(true)}
                >
                  <ImageSearchIcon />
                  <span className="sr-only">Upload image</span>
                </button>
                <input
                  type="text"
                  className="flex-1 p-2 border border-gray-300 rounded-lg"
                  placeholder="Type a message..."
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                />
                <button
                  className="bg-white text-theme p-2 rounded-r-lg flex items-center justify-center"
                  onClick={handleSend}
                >
                  <svg
                    className="w-5 h-5 rotate-90 rtl:-rotate-90"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                  </svg>
                  <span className="sr-only">Send message</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showUpload && (
        <div className="model" onClick={() => setShowUpload(false)}>
          <div
            className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space-y-5">
              <p className="text-theme">Upload File(s)</p>
              {isFileUpload ? (
                <RippleLoader />
              ) : (
                <>
                  {documentArray?.length ? (
                    <div className="flex flex-wrap gap-2">
                      {documentArray?.map((element, index) => {
                        return (
                          <>
                            {element.document_type === "doc" ? (
                              <div className="w-32 flex flex-col items-end space-y-2 group">
                                <button
                                  className="opacity-0 group-hover:opacity-100"
                                  onClick={() => removeItem(index)}
                                >
                                  <Close />
                                </button>
                                <div className="card h-24 w-full space-y-2 flex flex-col items-center justify-center border-4 border-transparent group-hover:border-light_green !py-1">
                                  <PDFDoc />
                                  <p className="max-w-full w-full text-sm truncate">
                                    {element?.name}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="w-20 flex flex-col items-end space-y-2 group">
                                <button
                                  className="opacity-0 group-hover:opacity-100"
                                  onClick={() => removeItem(index)}
                                >
                                  <Close />
                                </button>
                                <img
                                  src={element?.url}
                                  alt="img"
                                  className="h-24 w-full object-cover rounded-lg border-4 border-transparent group-hover:border-light_green"
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </>
              )}
              {uploadError && (
                <InputError
                  errorTitle={
                    "Please Drag and Drop or choose your file for upload"
                  }
                />
              )}
              <FileUploadChoose handleFileChange={handleUploadFile} />
              <div className="flex justify-end gap-4">
                <button
                  className="btn-outline uppercase"
                  onClick={() => setShowUpload(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-theme uppercase"
                  onClick={handleUploadOnChat}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedImageIndex !== null && (
        <ChatModal
          imageUrl={messages[selectedImageIndex].file}
          onClose={closeImageModal}
        />
      )}
    </>
  );
};

export default ChatList;
