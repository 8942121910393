import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CheckBox, RadioButton } from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import BusinessManages from "./BusinessManages";

const Cryptocurrency = ({
  cryptoCurrenciesData,
  formikOne,
  setCryptoCurrenciesSelectedArray,
  setCompanyCryptoActivitiesSelectedArray,
  setCurrenciesError,
  currenciesError,
  businessManagesError,
  setBusinessManagesError,
  cryptoCurrenciesValues,
  cryptoActivitiesValues,
  company_crypto_activities_other,
}) => {
  const getInitialValues = (arr, cryptoCurrenciesValues) => {
    let initial = {};

    // arr?.map((element) => {
    //   initial[element.id] = false;
    // });
    arr?.forEach((element) => {
      initial[element.id] = cryptoCurrenciesValues?.some(
        (val) => val.id === element.id
      );
    });
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(
      cryptoCurrenciesData,
      cryptoCurrenciesValues
    ),
  });

  useEffect(() => {
    let trueFieldKeys = Object.keys(formik.values).filter(
      (key) => formik.values[key]
    );
    setCryptoCurrenciesSelectedArray(trueFieldKeys);
    setCurrenciesError("");
  }, [formik.values]);
  return (
    <div className="space-y-2 px-3">
      <p className="sm:text-base text-sm sm:mb-0 mb-2">
        How is their business involved in cryptocurrency? (Select all that
        apply)
      </p>
      {currenciesError && <InputError errorTitle={currenciesError} />}
      {cryptoCurrenciesData?.map((element) => {
        return (
          <CheckBox
            idFrom={element.slug}
            label={element?.title}
            name={element.id}
            value={formik.values[element.id]}
            checked={formik.values[element.id]}
            onChange={formik.handleChange}
            className={"sm:text-base text-sm"}
          />
        );
      })}
      <p className="sm:text-base text-sm">
        Are they licensed by, or will they be seeking a license from, the
        Monetary Authority of Singapore (MAS) for cryptocurrency services or
        digital payment taken services or any MAS approved licenses?
      </p>
      {formikOne.errors.license_mas && formikOne.touched.license_mas ? (
        <InputError errorTitle={formikOne.errors.license_mas} />
      ) : null}
      <RadioButton
        label={"No"}
        idFrom={"license_mas_no"}
        value={"no"}
        checked={formikOne.values.license_mas === "no"}
        onChange={formikOne.handleChange}
        name={"license_mas"}
        className={"sm:text-base text-sm"}
      />
      <RadioButton
        label={"Yes"}
        idFrom={"license_mas_yes"}
        value="yes"
        checked={formikOne.values.license_mas === "yes"}
        onChange={formikOne.handleChange}
        onBlur={formikOne.handleBlur}
        name={"license_mas"}
        className={"sm:text-base text-sm"}
      />
      <p className="sm:text-base text-sm sm:mb-0 mb-2">
        Is their business related to any existing local and/or foreign entities
        in the cryptocurrency or blockchain industry or any MAS approved
        licenses?
      </p>
      {formikOne.errors.related_crypto_blockchain &&
      formikOne.touched.related_crypto_blockchain ? (
        <InputError errorTitle={formikOne.errors.related_crypto_blockchain} />
      ) : null}
      <RadioButton
        label={"No"}
        idFrom={"related_crypto_blockchain_no"}
        value={"no"}
        checked={formikOne.values.related_crypto_blockchain === "no"}
        onChange={formikOne.handleChange}
        name={"related_crypto_blockchain"}
        className={"sm:text-base text-sm"}
      />
      <RadioButton
        label={"Yes"}
        idFrom={"related_crypto_blockchain_yes"}
        value="yes"
        checked={formikOne.values.related_crypto_blockchain === "yes"}
        onChange={formikOne.handleChange}
        onBlur={formikOne.handleBlur}
        name={"related_crypto_blockchain"}
        className={"sm:text-base text-sm"}
      />
      {formik.values[3] && (
        <>
          {businessManagesError && (
            <InputError errorTitle={businessManagesError} />
          )}
          <p className="sm:text-base text-sm sm:mb-0 mb-2">
            Are they licensed by, or will they be seeking a license from, the
            Monetary Authority of Singapore (MAS) for cryptocurrency services or
            digital payment taken services or any MAS approved licenses?
          </p>
          <BusinessManages
            setCompanyCryptoActivitiesSelectedArray={
              setCompanyCryptoActivitiesSelectedArray
            }
            setBusinessManagesError={setBusinessManagesError}
            cryptoActivitiesValues={cryptoActivitiesValues}
            company_crypto_activities_other={company_crypto_activities_other}
          />
        </>
      )}
    </div>
  );
};

export default Cryptocurrency;
