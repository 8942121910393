import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CheckBox, TextareaWithLabel } from "../../../components/micro";

const CompanyFundResources = ({
  companyFundResourcesData,
  setCompanyFundResourcesSelectedArray,
  setCompanyFundResourcesError,
  companyFundResourcesVales,
  company_funds_other,
}) => {
  const getInitialValues = (arr, companyFundResourcesVales) => {
    let initial = {
      company_funds_other: company_funds_other ?? "",
    };

    arr?.forEach((element) => {
      initial[element.id] = companyFundResourcesVales?.some(
        (val) => val.id === element.id
      );
    });
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(
      companyFundResourcesData,
      companyFundResourcesVales
    ),
  });

  useEffect(() => {
    let trueFieldKeys = Object.keys(formik.values).filter(
      (key) => formik.values[key]
    );
    // remove company funds other form
    trueFieldKeys = trueFieldKeys.filter(function (item) {
      return item !== "company_funds_other";
    });
    setCompanyFundResourcesSelectedArray({
      companyFundResourcesSelectedArray: trueFieldKeys,
      company_funds_other: formik.values.company_funds_other,
    });
    setCompanyFundResourcesError("");
  }, [formik.values]);

  return (
    <>
      {companyFundResourcesData?.map((element) => {
        return (
          <CheckBox
            idFrom={element.slug}
            label={element?.title}
            name={element.id}
            value={formik.values[element.id]}
            checked={formik.values[element.id]}
            onChange={formik.handleChange}
            className={"lg:text-base sm:text-sm text-xs"}
          />
        );
      })}
      {formik.values[1] && (
        <TextareaWithLabel
          idFromName={"company_funds_other"}
          label={"Others"}
          className={"border"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.company_funds_other}
          name={"company_funds_other"}
          placeholder={"Enter other company funds"}
        />
      )}
    </>
  );
};

export default CompanyFundResources;
