import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getNotificationsList,
  readNotifications,
} from "../../redux/notifications/slice";
import { Close } from "../../assets/svg/AllSvg";

const ViewNotification = ({
  notificationData,
  onClose,
  pageCount,
  pageLimit,
  sort_by,
  sort_direction,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationData?.is_read === 0) {
      dispatch(
        readNotifications({ id: [notificationData?.id], read: true })
      ).then((res) => {
        if (res.type === "readNotifications/fulfilled") {
          dispatch(
            getNotificationsList({
              pageCount: pageCount,
              pageLimit: pageLimit,
              sort_by: sort_by,
              sort_direction: sort_direction,
            })
          );
        }
      });
    }
  }, []);

  return (
    <div className="model" onClick={onClose}>
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button className="absolute top-5 right-5" onClick={onClose}>
          <Close />
        </button>
        <div className="w-full text-center">
          <h2 className="text-2xl font-ebGaramond capitalize mb-3">
            {notificationData?.content}
          </h2>
          <p>{notificationData?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;
