import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../commons/RippleLoader";
import { Logo, login } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import { LoginAPI } from "../../redux/auth/slice";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginLoading } = useSelector((store) => ({
    loginLoading: store?.authData?.loading,
  }));

  const getAccessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    if (getAccessToken) {
      navigate("/");
    }
  }, []);

  const handleLogin = (values) => {
    dispatch(LoginAPI(values)).then((res) => {
      if (res.type === "LoginAPI/fulfilled") {
        const getCompanyList = JSON.parse(
          sessionStorage.getItem("companyList")
        );
        navigate("/", { state: { companyList: getCompanyList } });
      }
    });
  };

  return (
    <>
      <div className="main">
        {loginLoading ? (
          <RippleLoader />
        ) : (
          <div className="center-h">
            <div className="main-logo">
              <img
                src={Logo}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
            <section className="w-full">
              <div className="max-w-xl w-full mx-auto">
                <div className="main-view border">
                  <div className={"mb-8"}>
                    <p className="text-xs font-normal font-Poppins">Welcome!</p>
                    <h1 className="text-2xl font-ebGaramond font-normal mb-1">
                      Login to Ei Biz
                    </h1>
                  </div>
                  {/* Login flow */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      role: "customer",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Please Enter Email"),
                      password: Yup.string()
                        .required("Please Enter Password")
                        .min(
                          8,
                          "Password is too short - should be 8 characters minimum."
                        )
                        .matches(
                          /[a-zA-Z]/,
                          "Password can only contain latin letters."
                        ),
                    })}
                    onSubmit={(values) => {
                      handleLogin(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="space-y-5">
                          {/* User Name Or Email */}
                          <InputWithLabel
                            className={
                              errors.email && touched.email
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="email"
                            label="Username or Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            errors={errors.email && touched.email}
                            errorsText={errors.email}
                          />
                          <div className="space-y-2">
                            {/* password */}
                            <InputWithLabel
                              className={
                                errors.password && touched.password
                                  ? ` input-error `
                                  : ` border `
                              }
                              idFromName="password"
                              label="Password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              errors={errors.password && touched.password}
                              errorsText={errors.password}
                            />
                            {/* forget password */}
                            <Link
                              to={"/reset-password"}
                              className="border-b border-gray-400 inline-block"
                            >
                              Forget Password?
                            </Link>
                          </div>
                          <button type="submit" className="btn-theme w-full">
                            Log In
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="flex text-sm mt-5">
                    <p className="mr-1 font-light">Don't have an Account ?</p>
                    <Link to="/sign-up" className="cursor-pointer font-bold">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section className="hidden sm:flex items-center w-full">
              <img
                src={login}
                alt="logo"
                className="sm:max-w-80 lg:max-w-screen-sm"
              />
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default Login;
