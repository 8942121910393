import React from "react";
import { Registration, myCompany } from "../../assets/image";
import "../auth/Auth.css";
import "../dashboard/dashboard.css";
import { Calendar } from "iconsax-react";
import { Office, SmartHome } from "../../assets/svg/AllSvg";

const MyCompany = () => {
  return (
    <>
      <div className="w-full p-6">
        <div className="flex justify-between border-b border-b-secondary_lightGrey pb-8 mb-6">
          <div className="flex flex-row items-center">
            <img src={myCompany} className="" />
            <div>
              <p className="text-3xl font-ebGaramond font-normal">
                My Company Information
              </p>
              <h6 className="text-sm font-poppins font-normal mb-1">
                Overview of your company key information and dates to take note
                off
              </h6>
            </div>
          </div>
        </div>

        <div className="lg:flex  gap-2">
          <div className="">
            <div className="box-content w-80 rounded-lg drop-shadow-lg bg-white px-6  p-2 lg:mb-0 mb-4 ">
              <div className="p-1">
                <div>
                  <p className="text-xl font-ebGaramond font-normal ml-8">
                    My Company Pte Ltd
                  </p>
                  <div className="flex">
                    <img
                      src={Registration}
                      alt="logo"
                      className="object-cover w-6 h-6"
                    />
                    <div className="ml-2">
                      <p className="text-sm font-ebGaramond font-normal text-gray-700">
                        Registration No.
                      </p>
                      <p className="text-sm font-ebGaramond font-normal text-black">
                        00000000000X
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-1 ml-8">
                  <p className="text-sm font-ebGaramond font-normal text-gray-700">
                    Incorporation Date
                  </p>
                  <p className="text-sm font-ebGaramond font-normal">
                    DD/MM/YYYY
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="box-content w-80 rounded-lg drop-shadow-lg bg-white px-6  p-2">
              <div className="p-1">
                <div className="flex">
                  <Office />
                  <div>
                    <p className="text-xl font-ebGaramond font-normal ml-2">
                      Registered Office Address
                    </p>
                    <div className="ml-2">
                      <p className="text-sm font-ebGaramond font-normal text-gray-700 mt-1">
                        Address:
                      </p>
                      <p className="text-sm font-ebGaramond font-normal text-black">
                        Blk 123 Walk the Lane #01-11 <br /> Singapore, 123456{" "}
                        <br />
                        Singapore
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-themeLight rounded-lg py-8 px-8 mt-6">
        <div className="flex">
          <Calendar size="24" className=" text-theme" />
          <p className="font-ebGaramond text-theme text-xl ml-2">
            Dates to note:
          </p>
        </div>
        <div className="mt-2 flex justify-between">
          <div className="w-40">
            <p className="text-xs font-poppins font-light">
              Financial Year End
            </p>
            <p className="text-sm font-poppins">31 Dec 2023</p>
          </div>
          <div className="w-80">
            <p className="text-xs font-poppins font-light">
              Financial year end (FYE) is the completion of a one yearaccounting
              period. It will determine when your corporate filings and taxes
              are due.
            </p>
          </div>
        </div>
        <p className="border border-gray-400 mt-2"></p>

        <div className="mt-2 flex justify-between">
          <div className="w-40">
            <p className="text-xs font-poppins font-light">
              Annual General Meeting (AGM)
            </p>
            <p className="text-sm font-poppins">31 Dec 2023</p>
          </div>
          <div className="w-80">
            <p className="text-xs font-poppins font-light">
              EiBiz will prepare and send you documents to sign!
            </p>
          </div>
        </div>
        <p className="border border-gray-400 mt-2"></p>

        <div className="mt-2 flex justify-between">
          <div className="w-40">
            <p className="text-xs font-poppins font-light">Annual Return</p>
            <p className="text-sm font-poppins">31 Dec 2023</p>
          </div>
          <div className="w-80">
            <p className="text-xs font-poppins font-light">
              Annual Return (an electronic form that contains important
              information about the company) needs to be submitted to Accounting
              & Corporate Regulatory Authority (ACRA), by EiBIZ, as soon as your
              AGM has been held.
            </p>
          </div>
        </div>
        <p className="border border-gray-400 mt-2"></p>

        <div className="mt-2 flex justify-between">
          <div className="w-40">
            <p className="text-xs font-poppins font-light">
              Tax Return (Form C/C-S)
            </p>
            <p className="text-sm font-poppins">31 Dec 2023</p>
          </div>
          <div className="w-80">
            <p className="text-xs font-poppins font-light">
              Tax return (Form C/C-S) needs to be submitted by EiBiz to Inland
              Revenue Authority of Singapore (IRAS) for the Year of Assessment
              2024 corresponding to your Financial Years ending on 31 Dec 2023.
            </p>
          </div>
        </div>
        <p className="border border-gray-400 mt-2"></p>
      </div>

      <div className="bg-greenLight rounded-lg py-8 px-8 mt-6">
        <div className="flex">
          <SmartHome />
          <p className="font-ebGaramond text-theme text-xl ml-2">
            Principal Activities
          </p>
        </div>
        <div className="mt-2 flex justify-between">
          <div className="w-36">
            <p className="text-xs font-poppins font-light">
              Primary Activities [12345] E-Commerce
            </p>
          </div>
          <div className="w-80">
            <p className="text-xs font-poppins font-light">
              Secondary Activity <br />
              [54321] Retail stores
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCompany;
