import React, { useContext, useState, useEffect, useRef } from "react";
import { HambergerMenu } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Icon,
  Notifications,
  ShoppingCart,
} from "../assets/svg/AllSvg";
import { Logo } from "../assets/image";
import { getCompany } from "../redux/companies/slice";
import { getProfile } from "../redux/auth/slice";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import layoutContext from "./layoutContext";

const Header = () => {
  const { isAsideOpen, setIsAsideOpen } = useContext(layoutContext);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Selector
  const { GetCompany, getProfileData } = useSelector((store) => ({
    // Company Data
    GetCompany: store?.companiesData?.getCompanyStatus?.data?.data?.data,
    getProfileData: store?.authData?.getProfileDataStatus?.data,
  }));

  // Get APIs
  useEffect(() => {
    dispatch(getCompany());
    dispatch(getProfile());
  }, []);

  const Modal = ({ isOpen, onClose }) => {
    const [copyMessage, setCopyMessage] = useState("");
    const modalRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleLogOut = () => {
      setIsModalVisible(true);
    };

    const handleConfirmLogout = () => {
      console.log("User logged out");
      setIsModalVisible(false);
    };

    const handleCancelLogout = () => {
      setIsModalVisible(false);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, onClose]);

    const handleCopy = () => {
      navigator.clipboard
        .writeText(GetCompany[0]?.company_id)
        .then(() => {
          setCopyMessage("Registration number copied!");
          setTimeout(() => {
            setCopyMessage("");
          }, 2000);
        })
        .catch((err) => {
          setCopyMessage("Failed to copy registration number.");
          setTimeout(() => {
            setCopyMessage("");
          }, 2000);
        });
    };

    const handleProfile = () => {
      navigate("/profile");
      onClose();
    };

    const LogoutConfirmationModal = ({ isVisible, onConfirm, onCancel }) => {
      if (!isVisible) return null;

      const logOutClear = () => {
        sessionStorage.clear();
        navigate("/login");
      };

      return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <p className="font-normal font-ebGaramond text-2xl">
              Confirm Logout?
            </p>
            <div className="mt-4">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="bg-theme text-white px-4 py-2 rounded "
                onClick={logOutClear}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        {isOpen && (
          <div className="static flex justify-end">
            <div
              ref={modalRef}
              className="bg-lightBlue rounded-lg shadow-lg absolute pb-2 top-20 right-6"
            >
              <div className="px-5 pb-4">
                <div className="py-4 flex">
                  <div>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_5834_3412)">
                        <rect width="40" height="40" rx="4" fill="#BDBDBD" />
                        <path
                          d="M14.1377 27.14C12.8443 27.14 11.6643 26.84 10.5977 26.24C9.53099 25.6267 8.68432 24.78 8.05766 23.7C7.44432 22.6067 7.13766 21.38 7.13766 20.02C7.13766 18.66 7.44432 17.44 8.05766 16.36C8.68432 15.2667 9.53099 14.42 10.5977 13.82C11.6643 13.2067 12.8443 12.9 14.1377 12.9C15.4443 12.9 16.631 13.2067 17.6977 13.82C18.7643 14.42 19.6043 15.26 20.2177 16.34C20.831 17.42 21.1377 18.6467 21.1377 20.02C21.1377 21.3933 20.831 22.62 20.2177 23.7C19.6043 24.78 18.7643 25.6267 17.6977 26.24C16.631 26.84 15.4443 27.14 14.1377 27.14ZM14.1377 25.56C15.111 25.56 15.9843 25.3333 16.7577 24.88C17.5443 24.4267 18.1577 23.78 18.5977 22.94C19.051 22.1 19.2777 21.1267 19.2777 20.02C19.2777 18.9 19.051 17.9267 18.5977 17.1C18.1577 16.26 17.551 15.6133 16.7777 15.16C16.0043 14.7067 15.1243 14.48 14.1377 14.48C13.151 14.48 12.271 14.7067 11.4977 15.16C10.7243 15.6133 10.111 16.26 9.65766 17.1C9.21766 17.9267 8.99766 18.9 8.99766 20.02C8.99766 21.1267 9.21766 22.1 9.65766 22.94C10.111 23.78 10.7243 24.4267 11.4977 24.88C12.2843 25.3333 13.1643 25.56 14.1377 25.56ZM33.0003 17.14C33.0003 18.3 32.6003 19.2667 31.8003 20.04C31.0136 20.8 29.807 21.18 28.1803 21.18H25.5003V27H23.6803V13.06H28.1803C29.7536 13.06 30.947 13.44 31.7603 14.2C32.587 14.96 33.0003 15.94 33.0003 17.14ZM28.1803 19.68C29.1936 19.68 29.9403 19.46 30.4203 19.02C30.9003 18.58 31.1403 17.9533 31.1403 17.14C31.1403 15.42 30.1536 14.56 28.1803 14.56H25.5003V19.68H28.1803Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5834_3412">
                          <rect width="40" height="40" rx="4" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ml-2">
                    <div className="flex justify-between">
                      <p className="font-poppins font-normal text-sm text-black">
                        {getProfileData?.full_name}
                      </p>
                      <button className="font-poppins font-normal text-xs text-black p-1 border border-gray-400 rounded-3xl">
                        Approver
                      </button>
                    </div>
                    <p className="font-poppins font-normal text-xs text-black">
                      {getProfileData?.email}
                    </p>
                  </div>
                </div>

                <p className="font-poppins font-normal text-sm text-black">
                  {GetCompany[0]?.name}
                </p>
                <div className="mt-2 flex items-center">
                  <div>
                    <p className="font-poppins font-normal text-sm text-black">
                      Registration No.
                    </p>
                    <p className="font-poppins font-normal text-xs text-black">
                      {GetCompany[0]?.company_id}
                    </p>
                  </div>
                  <button onClick={handleCopy}>
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.5 13H16.5C15.4 13 14.5 13.9 14.5 15V29H16.5V15H28.5V13ZM31.5 17H20.5C19.4 17 18.5 17.9 18.5 19V33C18.5 34.1 19.4 35 20.5 35H31.5C32.6 35 33.5 34.1 33.5 33V19C33.5 17.9 32.6 17 31.5 17ZM31.5 33H20.5V19H31.5V33Z"
                        fill="black"
                        fillOpacity="0.54"
                      />
                    </svg>
                  </button>
                </div>
                {copyMessage && (
                  <p className="mt-1 text-green text-xs">{copyMessage}</p>
                )}
                <div className="mt-2">
                  <p className="font-poppins font-normal text-sm text-black">
                    Company Status
                  </p>
                  {GetCompany[0]?.status === "inactive" ? (
                    <p className="font-poppins font-normal text-xs text-red">
                      {GetCompany[0]?.status}
                    </p>
                  ) : (
                    <p className="font-poppins font-normal text-xs text-green">
                      {GetCompany[0]?.status}
                    </p>
                  )}
                </div>
                <div className="mt-2">
                  <p className="font-poppins font-normal text-sm text-black">
                    Incorporation Date
                  </p>
                  <p className="font-poppins font-normal text-xs text-black">
                    {GetCompany[0]?.created_at
                      ? format(
                          new Date(GetCompany[0]?.created_at),
                          "dd/MM/yyyy"
                        )
                      : "-"}
                  </p>
                </div>
              </div>
              <p className="border border-gray-300" />
              <div className="mt-2">
                <button
                  className="hover:bg-gray-300 text-black p-1 w-full justify-start"
                  onClick={handleProfile}
                >
                  <div className="px-4 flex items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
                        fill="black"
                        fillOpacity="0.54"
                      />
                    </svg>
                    <p className="ml-4 font-poppins font-normal text-sm">
                      My Profile
                    </p>
                  </div>
                </button>
              </div>
              <div className="ml-1">
                <button
                  className="hover:bg-gray-300 text-black p-1 w-full justify-start"
                  onClick={handleLogOut}
                >
                  <div className="px-4 flex items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z"
                        fill="black"
                        fillOpacity="0.54"
                      />
                    </svg>

                    <p className="ml-4 font-poppins font-normal text-sm">
                      Logout
                    </p>
                  </div>
                </button>
                <LogoutConfirmationModal
                  isVisible={isModalVisible}
                  onConfirm={handleConfirmLogout}
                  onCancel={handleCancelLogout}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <header className="sticky top-0 left-0 w-full z-[9]">
        <div className="shadow-md bg-secondary h-16 w-full flex justify-between items-center px-4 ">
          <div className="flex gap-2 cursor-pointer">
            <span>
              <div className="text-white flex items-center">
                <span onClick={() => setIsAsideOpen(!isAsideOpen)}>
                  <HambergerMenu size="22" className="mr-1 ml-6" />
                </span>
                <div className="mx-2" onClick={() => navigate("/")}>
                  <img className="h-8 w-8" src={Logo} alt="logo" />
                </div>
                <p className="text-sm">EI BIZ</p>
              </div>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div
              className="relative cursor-pointer"
              onClick={() => {
                navigate("/notifications");
              }}
            >
              <Notifications />
              <span className="absolute top-0 right-0 translate-x-1 w-1.5 h-1.5 bg-secondaryLight rounded-full"></span>
            </div>
            <div
              className="relative cursor-pointer"
              onClick={() => {
                navigate("/chat");
              }}
            >
              <Icon />
              <span className="absolute top-0 right-0 -translate-y-1 translate-x-1 text-xs text-white">
                1
              </span>
            </div>
            <div className="relative">
              <ShoppingCart pathClass={"fill-white"} fillOpacity={1} />
              <span className="absolute top-0 right-0 -translate-y-1 translate-x-1 text-xs text-white">
                1
              </span>
            </div>
            <div
              className="cursor-pointer"
              // onClick={() => navigate("/profile")}
              onClick={openModal}
            >
              <Avatar />
            </div>
          </div>
        </div>
        <Modal isOpen={isOpen} onClose={closeModal} />
      </header>
    </>
  );
};

export default Header;
