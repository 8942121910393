import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import CompanyContext from "./CompanyContext";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircle } from "iconsax-react";
import { getCountryData } from "../../redux/address/slice";
import {
  businessActivities,
  companyData,
  companyExist,
  companyUENExist,
  CreateCompany,
} from "../../redux/companies/slice";
import RippleLoader from "../../commons/RippleLoader";
import {
  CheckBox,
  InputWithLabel,
  RadioButton,
  TextareaWithLabel,
  Tooltip,
} from "../../components/micro";
import {
  Accounting,
  ArrowForward,
  BackArrow,
  CheckCircleOutline,
  CorporateSecretary,
  Info,
  MoneyApprovedCreditcardIcon,
} from "../../assets/svg/AllSvg";
import { InputError } from "../../commons/MicroComponents";
import CompanyAttractionsFunds from "./components/CompanyAttractionsFunds";
import CompanyFundResources from "./components/CompanyFundResources";
import Objectives from "./components/Objectives";
import Cryptocurrency from "./components/Cryptocurrency";
import ButtonLoader from "../../commons/ButtonLoader";
import { useNavigate } from "react-router-dom";

const CompanyInfo = ({
  companyId,
  setCompanyId,
  organisationDetails,
  CompanyData,
  type,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [uniqueEntityNumber, setUniqueEntityNumber] = useState("");
  const [businessActivity, setBusinessActivity] = useState("");
  const [countryArray, setCountryArray] = useState([]);
  const [businessActivitiesArray, setBusinessActivitiesArray] = useState([]);
  const [attractionsSelectedArray, setAttractionsSelectedArray] = useState({});
  const [objectivesSelectedArray, setObjectivesSelectedArray] = useState({});
  const [
    companyCryptoActivitiesSelectedArray,
    setCompanyCryptoActivitiesSelectedArray,
  ] = useState({});
  const [cryptoCurrenciesSelectedArray, setCryptoCurrenciesSelectedArray] =
    useState([]);
  const [
    companyFundResourcesSelectedArray,
    setCompanyFundResourcesSelectedArray,
  ] = useState([]);
  const [companyExistLoad, setCompanyExistLoad] = useState(false);
  const [companyExistError, setCompanyExistError] = useState(false);
  const [companyExistMessage, setCompanyExistMessage] = useState("");
  const [companyUENExistLoad, setCompanyUENExistLoad] = useState(false);
  const [companyUENExistError, setCompanyUENExistError] = useState(false);
  const [companyUENExistMessage, setCompanyUENExistMessage] = useState("");
  const [cryptoCurrencyRadio, setCryptoCurrencyRadio] = useState(false);
  const [attractionsError, setAttractionsError] = useState("");
  const [companyFundResourcesError, setCompanyFundResourcesError] =
    useState("");
  const [businessManagesError, setBusinessManagesError] = useState("");
  const [objectivesError, setObjectivesError] = useState("");
  const [currenciesError, setCurrenciesError] = useState("");
  const [companyTypesArray, setCompanyTypesArray] = useState([]);
  const [defaultPrimaryActivity, setDefaultPrimaryActivity] = useState("");
  const [defaultSecondaryActivity, setDefaultSecondaryActivity] = useState("");
  const [defaultCompanyType, setDefaultCompanyType] = useState("");
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const { next } = useContext(CompanyContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Selector
  const {
    countryData,
    countryLoading,
    businessActivitiesData,
    businessActivitiesLoading,
    Loading,
  } = useSelector((store) => ({
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data,
    countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    // Loading
    Loading: store?.companiesData?.loading,
    // Business Activities Data
    businessActivitiesData:
      store?.companiesData?.businessActivitiesStatus?.data,
    businessActivitiesLoading:
      store?.companiesData?.businessActivitiesStatus?.loading,
  }));

  // Get APIs
  useEffect(() => {
    dispatch(getCountryData());
    dispatch(businessActivities());
  }, [dispatch]);

  // Set Dropdown Options
  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
    if (businessActivitiesData?.data) {
      let data = businessActivitiesData?.data?.map((x) => ({
        ...x,
        label: x.full_activity,
      }));
      setBusinessActivitiesArray(data);
    }
    if (CompanyData?.company_types) {
      let data = CompanyData?.company_types?.map((x) => ({
        ...x,
        label: x.title,
      }));
      setCompanyTypesArray(data);
    }
  }, [countryData, businessActivitiesData, CompanyData]);

  // Check Company Exist
  const handleCheckCompanyExist = (setFieldValue) => {
    setCompanyExistLoad(true);
    dispatch(companyExist({ id: companyId, company_name: companyName })).then(
      (res) => {
        setCompanyExistLoad(false);
        if (res.type === "companyExist/fulfilled") {
          if (res?.payload?.data?.exist) {
            // Company Not Exist
            setCompanyExistError(true);
            setCompanyExistMessage(res.payload.message);
            setCompanyName("");
            setUniqueEntityNumber("");
          } else {
            // Company Exist
            setFieldValue("isVerify", true);
            setCompanyExistError(false);
            setCompanyExistMessage(res?.payload?.message);
          }
        }
      }
    );
  };

  // Check Company UEN Exist
  const handleCheckUENExist = (setFieldValue) => {
    setCompanyUENExistLoad(true);
    dispatch(
      companyUENExist({ id: companyId, number: uniqueEntityNumber })
    ).then((res) => {
      setCompanyUENExistLoad(false);
      if (res.type === "companyUENExist/fulfilled") {
        if (res?.payload?.data?.exist) {
          // Company Not Exist
          setCompanyUENExistError(true);
          setCompanyUENExistMessage(res.payload.message);
          setCompanyName("");
          setUniqueEntityNumber("");
        } else {
          // Company Exist
          setFieldValue("isUNEVerify", true);
          setCompanyUENExistError(false);
          setCompanyUENExistMessage(res?.payload?.message);
        }
      }
    });
  };

  // set create Company Data
  useEffect(() => {
    if (organisationDetails?.id) {
      setCompanyName(organisationDetails?.name || "");
      setUniqueEntityNumber(organisationDetails?.uen || "");
      setCryptoCurrencyRadio(organisationDetails?.crypto_currency || "");

      const primaryActivity = businessActivitiesArray.find(
        (e) => e?.id === organisationDetails?.primary_activity?.id
      );
      setDefaultPrimaryActivity(primaryActivity || null);

      const secondaryActivity = businessActivitiesArray.find(
        (e) => e?.id === organisationDetails?.secondary_activity?.id
      );
      setDefaultSecondaryActivity(secondaryActivity || null);

      const companyType = companyTypesArray.find(
        (e) => e?.id === organisationDetails?.company_type_id
      );
      setDefaultCompanyType(companyType || null);

      const countriesDefaultArray =
        organisationDetails?.countries?.map((e) => ({
          label: e?.name,
          ...e,
        })) || [];
      setDefaultCountries(countriesDefaultArray);

      const corporateSecretaries =
        organisationDetails?.corporate_secretaries || {};
      setBusinessActivity(corporateSecretaries?.business_activity || "");

      const fullName = corporateSecretaries?.user?.full_name || "";
      const [firstName = "", lastName = ""] = fullName.split(" ");
      setFname(firstName);
      setLname(lastName);
    }
  }, [organisationDetails, CompanyData]);

  // Get Initial Values
  const getInitialValues = () => {
    let initial = {
      primary_activity: defaultPrimaryActivity ?? "",
      company_type: defaultCompanyType ?? "",
      describe_business_activity:
        organisationDetails?.primary_business_activity ?? "",
      secondary_activity: defaultSecondaryActivity ?? "",
      cryptocurrency: organisationDetails?.crypto_currency ? "yes" : "no",
      countries: defaultCountries ?? "",
      interested_d_o: organisationDetails?.interested_d_o === 1 ? true : false,
      approved_licenses: "",
      platforms: organisationDetails?.platform_id ?? "",
      dormant_company:
        organisationDetails?.dormant_company === 1 ? "yes" : "no" ?? "",
      first_name: fname ?? "",
      last_name: lname ?? "",
      email: organisationDetails?.corporate_secretaries?.user?.email ?? "",
      isVerify: organisationDetails?.name ? true : false,
      isUNEVerify: organisationDetails?.uen ? true : false,
      about_us_other: organisationDetails?.about_us_other ?? "",
    };
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({
      primary_activity: Yup.object().required("Please Select Primary Activity"),
      company_type: Yup.object()
        .nullable()
        .required("Please Select Company Type"),
      describe_business_activity: Yup.string().required(
        "Please Describe your Business Activity"
      ),
      secondary_activity: Yup.object().required(
        "Please Select Secondary Activity"
      ),
      isVerify:
        type === "eibiz"
          ? Yup.boolean()
          : Yup.boolean()
              .oneOf([true], "Please Check Availability Company Name")
              .required("Please Check Availability Company Name"),
      isUNEVerify:
        type === "eibiz"
          ? Yup.boolean()
              .oneOf([true], "Please Check Unique Entity Number (UEN)")
              .required("Please Check Unique Entity Number (UEN)")
          : Yup.boolean(),
      cryptocurrency: Yup.string().required(
        "Please Select your business involved in cryptocurrency"
      ),
      dormant_company:
        type === "eibiz"
          ? Yup.string().required("please select your dormant company")
          : Yup.string(),
      license_mas: cryptoCurrencyRadio
        ? Yup.string().required("Please Select")
        : Yup.string(),
      related_crypto_blockchain: cryptoCurrencyRadio
        ? Yup.string().required("Please Select")
        : Yup.string(),
      countries: Yup.array().min(1).required("Please Select Countries"),
      platforms: Yup.string().required("Please Select Platforms"),
      first_name:
        type === "eibiz"
          ? Yup.string().required("Please Select First Name")
          : Yup.string(),
      last_name:
        type === "eibiz"
          ? Yup.string().required("Please Select Last Name")
          : Yup.string(),
      email:
        type === "eibiz"
          ? Yup.string().required("Please Select Email")
          : Yup.string(),
    }),
    onSubmit: (values) => {
      if (
        type !== "eibiz" &&
        !attractionsSelectedArray?.attractionsSelectedArray?.length
      ) {
        setAttractionsError("Please Select");
        return;
      }
      if (
        !companyFundResourcesSelectedArray?.companyFundResourcesSelectedArray
          ?.length
      ) {
        setCompanyFundResourcesError("Please Select");
        return;
      }
      if (!objectivesSelectedArray?.objectivesSelectedArray.length) {
        setObjectivesError("Please Select");
        return;
      }
      if (cryptoCurrencyRadio && !cryptoCurrenciesSelectedArray.length) {
        setCurrenciesError("Please Select");
        return;
      }

      const payload = {
        id: companyId,
        name: companyName,
        type: type,
        uen: values.uen,
        primary_activity: values.primary_activity.id,
        company_type_id: values.company_type.id,
        secondary_activity: values.secondary_activity.id,
        business_activity: values.describe_business_activity,
        crypto_currency: values.cryptocurrency === "yes" ? true : false,
        interested_d_o: values.interested_d_o,
        attractions: attractionsSelectedArray?.attractionsSelectedArray,
        attractions_other: attractionsSelectedArray?.attractions_other,
        company_funds:
          companyFundResourcesSelectedArray?.companyFundResourcesSelectedArray,
        company_funds_other:
          companyFundResourcesSelectedArray?.company_funds_other,
        countries: values.countries.map((e) => e.id),
        platform_id: values.platforms,
        objectives: objectivesSelectedArray?.objectivesSelectedArray,
        objectives_other: objectivesSelectedArray?.objectives_other,
        crypto_currencies: cryptoCurrenciesSelectedArray,
        license_mas: values.license_mas === "no" ? false : true,
        related_crypto_blockchain:
          values.related_crypto_blockchain === "no" ? false : true,
        company_crypto_activities:
          companyCryptoActivitiesSelectedArray?.companyCryptoActivitiesSelectedArray,
        company_crypto_activities_other:
          companyCryptoActivitiesSelectedArray?.company_crypto_activities_other,
        dormant_company: values.dormant_company === "yes" ? true : false,
        uen: uniqueEntityNumber,
        about_us_other: values.about_us_other,
        corporate_secretary: {
          role: "corporate_secretary",
          businessActivity: businessActivity,
          full_name: values.first_name + " " + values.last_name,
          email: values.email,
        },
      };

      dispatch(CreateCompany(payload)).then((res) => {
        if (res.type === "CreateCompany/fulfilled") {
          setCompanyId(res?.payload?.data?.id);
          next();
        }
      });
    },
  });

  return (
    <>
      {Loading ? (
        <RippleLoader />
      ) : (
        <div>
          <div className="border-b border-secondary_grey sm:pb-2 pb-1 sm:mb-5 mb-2">
            <p className="lg:text-md text-sm sm:mb-0 mb-1">First thing First</p>
            <p className="font-ebGaramond lg:text-2xl text-xl sm:mb-3 mb-1">
              Let's Start With Your Company Information
            </p>
          </div>
          {/* Check Availability */}
          <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
            {type === "eibiz" ? (
              <>
                <p className="sm:text-base text-sm">1. Company details</p>
                <p className="text-sm">
                  If you don't have your UEN handy{" "}
                  <button className="text-theme border-b border-theme sm:text-base text-sm">
                    search ACRA register here
                  </button>
                </p>
                <div className="flex items-start sm:gap-5 gap-2 sm:flex-row flex-col">
                  <div className="w-full">
                    <InputWithLabel
                      className="border"
                      idFromName="unique_entity_number"
                      label="Your Unique Entity Number (UEN)"
                      type="text"
                      onChange={(e) => {
                        setUniqueEntityNumber(e.target.value);
                        formik.setFieldValue("isUNEVerify", false);
                      }}
                      value={uniqueEntityNumber}
                    />
                  </div>
                  <button
                    disabled={
                      !uniqueEntityNumber?.length || companyUENExistLoad
                    }
                    className={`${
                      !uniqueEntityNumber?.length || companyUENExistLoad
                        ? "btn-outline-disabled"
                        : "btn-theme"
                    } whitespace-nowrap sm:text-base text-sm !py-2.5`}
                    onClick={() => {
                      if (type === "eibiz") {
                        handleCheckUENExist(formik.setFieldValue);
                      } else {
                        handleCheckCompanyExist(formik.setFieldValue);
                      }
                    }}
                  >
                    {companyUENExistLoad ? "Loading...." : "Check"}
                  </button>
                </div>
                {formik.errors.isUNEVerify && formik.touched.isUNEVerify ? (
                  <InputError errorTitle={formik.errors.isUNEVerify} />
                ) : null}
                {companyUENExistMessage && (
                  <div
                    className={`flex items-center ${
                      companyUENExistError
                        ? "text-dark_extra_red bg-light_extra_red"
                        : "text-success bg-green/20"
                    } space-x-2 rounded px-5 py-3`}
                  >
                    {companyUENExistError ? (
                      <InfoCircle size="20" />
                    ) : (
                      <CheckCircleOutline />
                    )}
                    <span className="font-normal sm:text-base text-sm">
                      {companyUENExistMessage}
                    </span>
                  </div>
                )}
                <div className="w-full">
                  <InputWithLabel
                    className="border"
                    idFromName="company_name"
                    label="Your Company Name"
                    type="text"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      formik.setFieldValue("isVerify", false);
                    }}
                    value={companyName}
                  />
                </div>
                {formik.errors.isVerify && formik.touched.isVerify ? (
                  <InputError errorTitle={formik.errors.isVerify} />
                ) : null}
                {companyExistMessage && (
                  <div
                    className={`flex items-center ${
                      companyExistError
                        ? "text-dark_extra_red bg-light_extra_red"
                        : "text-success bg-green/20"
                    } space-x-2 rounded px-5 py-3`}
                  >
                    {companyExistError ? (
                      <InfoCircle size="20" />
                    ) : (
                      <CheckCircleOutline />
                    )}
                    <span className="font-normal">{companyExistMessage}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="sm:text-base text-sm sm:mb-0 mb-2">
                  1. Check your company name here
                </p>
                <p className="sm:text-sm text-xs ">
                  Before we start incorporating your company in Singapore with
                  you, you would need to make sure you have a unique company
                  name.
                </p>
                <div className="sm:flex items-start gap-5">
                  <div className="w-full">
                    <InputWithLabel
                      className="border sm:text-base text-sm"
                      idFromName="company_name"
                      label="Your Company Name"
                      type="text"
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        formik.setFieldValue("isVerify", false);
                      }}
                      value={companyName}
                    />
                    <p className="text-light_grey sm:text-sm text-xs mt-1">
                      *Company name availability is subject to final
                      confirmation from regulating authority
                    </p>
                  </div>
                  <button
                    disabled={!companyName?.length || companyExistLoad}
                    className={`${
                      !companyName?.length || companyExistLoad
                        ? "btn-outline-disabled"
                        : "btn-theme"
                    } whitespace-nowrap sm:text-base text-sm !py-2.5`}
                    onClick={() =>
                      handleCheckCompanyExist(formik.setFieldValue)
                    }
                  >
                    {companyExistLoad ? "Loading...." : "Check"}
                  </button>
                </div>
                {formik.errors.isVerify && formik.touched.isVerify ? (
                  <InputError errorTitle={formik.errors.isVerify} />
                ) : null}
                {companyExistMessage && (
                  <div
                    className={`flex items-center ${
                      companyExistError
                        ? "text-dark_extra_red bg-light_extra_red"
                        : "text-success bg-green/20"
                    } space-x-2 rounded px-5 py-3`}
                  >
                    {companyExistError ? (
                      <InfoCircle size="20" />
                    ) : (
                      <CheckCircleOutline />
                    )}
                    <span className="font-normal">{companyExistMessage}</span>
                  </div>
                )}
              </>
            )}
            {/* Company Type */}
            <div>
              <p className="md:text-base text-sm ">
                Please select your company type:
              </p>
              <ReactSelect
                className={"text-black sm:text-base text-sm p-0"}
                id="company_type"
                placeholder="Company Type"
                options={companyTypesArray}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={formik.values.company_type}
                onChange={(e) => {
                  formik.setFieldValue("company_type", e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid ${
                      formik.errors.company_type && formik.touched.company_type
                        ? "red"
                        : "#0000003B"
                    }`,
                    borderRadius: "0.25rem",
                    padding: "6px 4px",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #0000003B",
                    },
                  }),
                }}
              />
              {formik.errors.company_type && formik.touched.company_type ? (
                <InputError errorTitle={formik.errors.company_type} />
              ) : null}
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <>
              {/* business activity */}
              <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                <div className="space-y-3">
                  {type === "eibiz" ? (
                    <>
                      <p className=" sm:text-base text-sm  ">
                        2. Classify your company
                      </p>
                      <p className="sm:text-sm text-xs">
                        Selecting a Standard Industrial Classification code
                        (SSIC) is essential when incorporating in <br />{" "}
                        Singapore.
                      </p>
                      <span className="text-theme border-b sm:text-base text-sm border-theme inline-block cursor-pointer">
                        Quick Guide
                      </span>
                    </>
                  ) : (
                    <>
                      <p className="sm:text-base text-sm sm:mb-0 mb-2">
                        2. What’s your business activity?
                      </p>
                      <p className="sm:text-sm text-xs">
                        To incorporate in Singapore, you will need to select a
                        Singapore Standard Industrial Classification (SSIC) code
                        for your business.
                      </p>
                      <span className="text-theme border-b sm:text-base text-sm border-theme inline-block cursor-pointer">
                        Quick Guide
                      </span>
                    </>
                  )}
                </div>
                {/* SSIC - Company Primary Activity */}
                <div>
                  <ReactSelect
                    className={"text-black sm:text-base text-sm p-0"}
                    id="primary_activity"
                    placeholder="SSIC - Company Primary Activity"
                    options={businessActivitiesArray}
                    isLoading={businessActivitiesLoading}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    value={formik.values.primary_activity}
                    onChange={(e) => {
                      formik.setFieldValue("primary_activity", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          formik.errors.primary_activity &&
                          formik.touched.primary_activity
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {formik.errors.primary_activity &&
                  formik.touched.primary_activity ? (
                    <InputError errorTitle={formik.errors.primary_activity} />
                  ) : null}
                </div>
                {/* Please describe your business activity */}
                <div>
                  <InputWithLabel
                    className={
                      formik.errors.describe_business_activity &&
                      formik.touched.describe_business_activity
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="describe_business_activity"
                    label="Please describe your business activity"
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.describe_business_activity}
                    errors={
                      formik.errors.describe_business_activity &&
                      formik.touched.describe_business_activity
                    }
                    errorsText={formik.errors.describe_business_activity}
                  />
                  <p className="text-light_grey sm:text-sm text-xs mt-1">
                    A minimum 100 Characters
                  </p>
                </div>
                {/* SSIC - Company Secondary Activity */}
                <div>
                  <ReactSelect
                    className={"text-black sm:text-base text-sm p-0"}
                    id="secondary_activity"
                    placeholder="SSIC - Company Secondary Activity"
                    options={businessActivitiesArray}
                    isLoading={false}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    value={formik.values.secondary_activity}
                    onChange={(e) => {
                      formik.setFieldValue("secondary_activity", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          formik.errors.secondary_activity &&
                          formik.touched.secondary_activity
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {formik.errors.secondary_activity &&
                  formik.touched.secondary_activity ? (
                    <InputError errorTitle={formik.errors.secondary_activity} />
                  ) : null}
                </div>
                {/* Cryptocurrency Declaration */}
                <p className="md:text-base text-sm sm:mb-0">
                  Cryptocurrency Declaration
                </p>
                <p className="md:text-base text-sm">
                  Is your business involved in cryptocurrency
                </p>
                <RadioButton
                  label={"No"}
                  idFrom={"no"}
                  value={"no"}
                  checked={formik.values.cryptocurrency === "no"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setCryptoCurrencyRadio(false);
                  }}
                  name={"cryptocurrency"}
                />
                <RadioButton
                  label={"Yes"}
                  idFrom={"Yes"}
                  value="yes"
                  checked={formik.values.cryptocurrency === "yes"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setCryptoCurrencyRadio(true);
                  }}
                  onBlur={formik.handleBlur}
                  name={"cryptocurrency"}
                />
                {/* Crypto Currency */}
                {formik.values.cryptocurrency === "yes" && (
                  <Cryptocurrency
                    cryptoCurrenciesData={CompanyData.crypto_currencies}
                    setCryptoCurrenciesSelectedArray={
                      setCryptoCurrenciesSelectedArray
                    }
                    formikOne={formik}
                    companyCryptoActivitiesSelectedArray={
                      companyCryptoActivitiesSelectedArray
                    }
                    setCompanyCryptoActivitiesSelectedArray={
                      setCompanyCryptoActivitiesSelectedArray
                    }
                    currenciesError={currenciesError}
                    setCurrenciesError={setCurrenciesError}
                    businessManagesError={businessManagesError}
                    setBusinessManagesError={setBusinessManagesError}
                    cryptoCurrenciesValues={
                      organisationDetails?.crypto_currencies
                    }
                    cryptoActivitiesValues={
                      organisationDetails?.crypto_activities
                    }
                    company_crypto_activities_other={
                      organisationDetails?.company_crypto_activities_other
                    }
                  />
                )}
                {/* Interested */}
                <div className="border border-secondary_grey rounded-lg flex items-center gap-5 px-4 py-3">
                  <div className="max-w-9 w-full">
                    <MoneyApprovedCreditcardIcon />
                  </div>
                  <div className="space-y-2">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      Director's & Officers (D&O)
                    </p>
                    <p className="sm:text-sm text-xs">
                      Protect yourself and your fellow directors from
                      allegations of wrongful acts or breaches of duty (e.g.,
                      errors & omissions, breach of PDPA) while acting on behalf
                      of the company.
                    </p>
                    <div>
                      <CheckBox
                        idFrom={"interested_d_o"}
                        label={"I am interested to learn more"}
                        name={"interested_d_o"}
                        value={"yes"}
                        checked={formik.values.interested_d_o}
                        onChange={formik.handleChange}
                        className={"sm:text-sm text-xs "}
                      />
                    </div>
                    {formik.values.interested_d_o && (
                      <div className="flex justify-between bg-lightPrimaryShades px-4 py-2">
                        <p className="sm:text-base text-sm">Starting From</p>
                        <div className="text-right">
                          <p className="text-xs">Annual Fees</p>
                          <p>${CompanyData?.other?.directors_officers_d_o}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {type === "eibiz" ? (
                <>
                  <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      3. Services required
                    </p>
                    <p className="sm:text-sm text-xs">
                      If you are transferring Corporate Secretary, we will
                      liaise with your current provider for a smooths transfer.
                    </p>
                    <div className="space-y-2">
                      <p className="sm:text-base text-sm sm:mb-0 mb-2">
                        I need to transfer:
                      </p>
                      <div className="grid md:grid-cols-2 grid-rows gap-4">
                        <button
                          type="button"
                          className="bg-primary_shades_light h-20 px-4 items-center flex justify-start rounded-lg border border-secondaryGray"
                        >
                          <CorporateSecretary />
                          <p className="ml-2 font-normal sm:text-base text-sm">
                            Corporate Secretary
                          </p>
                        </button>
                        <button
                          type="button"
                          disabled
                          className="bg-disable_grey h-20 px-6 items-center flex justify-start gap-3 rounded-lg border border-gray-200"
                        >
                          <Accounting />
                          <div>
                            <p className="ml-2 font-normal sm:text-base text-sm text-grey ">
                              Accounting
                            </p>
                            <p className="text-xs text-grey">Coming Soon!</p>
                          </div>
                        </button>
                      </div>
                      <p className="sm:text-sm text-xs">
                        Details of your current corporate secretary. Type “N/A”
                        if you do not have this information at hand
                      </p>
                    </div>
                    <div>
                      <div className="w-full">
                        <InputWithLabel
                          className="border"
                          idFromName="businessActivity"
                          label="Please describe your business activity"
                          type="text"
                          onChange={(e) => setBusinessActivity(e.target.value)}
                          value={businessActivity}
                        />
                      </div>
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-1/2">
                          <InputWithLabel
                            className="border"
                            idFromName="first_name"
                            label="First Name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                            errors={
                              formik.errors.first_name &&
                              formik.touched.first_name
                            }
                            errorsText={formik.errors.first_name}
                          />
                        </div>
                        <div className="w-1/2">
                          <InputWithLabel
                            className="border"
                            idFromName="last_name"
                            label="Last Name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            errors={
                              formik.errors.last_name &&
                              formik.touched.last_name
                            }
                            errorsText={formik.errors.last_name}
                          />
                        </div>
                      </div>
                      <div className="w-full mt-2">
                        <InputWithLabel
                          className="border"
                          idFromName="email"
                          label="Email"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          errors={formik.errors.email && formik.touched.email}
                          errorsText={formik.errors.email}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                  <p className="sm:text-base text-sm sm:mb-0 mb-2">
                    3. Source of your company funds
                  </p>
                  <p className="sm:text-sm text-xs">
                    Your inputs are crucial for compliance checks.
                  </p>
                  <div className="space-y-2 px-3">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      What attracts you to incorporate your business in
                      Singapore?
                    </p>
                    {attractionsError && (
                      <InputError errorTitle={attractionsError} />
                    )}
                    <CompanyAttractionsFunds
                      AttractionsData={CompanyData?.attractions}
                      attractionsFundsVales={organisationDetails?.attractions}
                      attractions_other={organisationDetails?.attractions_other}
                      setAttractionsSelectedArray={setAttractionsSelectedArray}
                      attractionsError={attractionsError}
                      setAttractionsError={setAttractionsError}
                    />
                  </div>
                  <div className="space-y-2 px-3">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      Please select all sources for your company's fund
                    </p>
                    {companyFundResourcesError && (
                      <InputError errorTitle={companyFundResourcesError} />
                    )}
                    <CompanyFundResources
                      companyFundResourcesData={CompanyData?.fund_resources}
                      companyFundResourcesVales={
                        organisationDetails?.company_funds
                      }
                      company_funds_other={
                        organisationDetails?.company_funds_other
                      }
                      setCompanyFundResourcesSelectedArray={
                        setCompanyFundResourcesSelectedArray
                      }
                      setCompanyFundResourcesError={
                        setCompanyFundResourcesError
                      }
                    />
                    {formik.errors.company_funds &&
                    formik.touched.company_funds ? (
                      <InputError errorTitle={formik.errors.company_funds} />
                    ) : null}
                  </div>
                  <p className="sm:text-base text-sm sm:mb-0 mb-2">
                    Please list up to 3 countries where your business will take
                    place.{" "}
                  </p>
                  {/* Country */}
                  <div>
                    <div
                      className={`${
                        formik.errors.countries && formik.touched.countries
                          ? ` input-error `
                          : ""
                      } relative
              bg-white transition duration-300 rounded plus-number-inputs`}
                    >
                      <ReactSelect
                        className={"text-black sm:text-base text-sm p-0"}
                        id="countries"
                        placeholder="Countries"
                        options={countryArray}
                        isLoading={countryLoading}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        value={formik.values.countries}
                        isMulti
                        onChange={(e) => {
                          formik.setFieldValue("countries", e);
                          formik.setFieldValue("countryId", e?.id);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              formik.errors.countries &&
                              formik.touched.countries
                                ? "red"
                                : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />
                      <label
                        htmlFor="countries"
                        className="absolute text-light_grey pointer-events-none sm:text-base text-sm bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Countries
                      </label>
                    </div>
                    {formik.errors.countries && formik.touched.countries ? (
                      <InputError errorTitle={formik.errors.countries} />
                    ) : null}
                  </div>
                </div>
              )}

              {type === "eibiz" ? (
                <>
                  <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      4. Dormant company
                    </p>
                    <div className="flex gap-2 mt-1 max-w-96">
                      <p className=" text-sm">Is your company dormant</p>
                      <Tooltip
                        tooltipLabelClass={"min-w-96 max-w-96"}
                        label={<Info />}
                        tooltipLabel={
                          <>
                            <div className="w-full">
                              <p className="font-semibold sm:text-base text-sm mb-1">
                                To qualify as a dormant company in Singapore:
                              </p>
                              <ul className="list-decimal pl-4 mb-4">
                                <li className="sm:text-base text-sm">
                                  No income during the financial year
                                </li>
                                <li className="sm:text-base text-sm">
                                  No expenses during the financial year, except
                                  statutory expenses (eg. Corporate secretary,
                                  bank fees)
                                </li>
                                <li className="sm:text-base text-sm">
                                  Asset value under $500,000
                                </li>
                              </ul>
                              <p className="sm:text-base text-sm">Note:*</p>
                              <p className="text-xs">
                                You will be upgraded to our standard corporate
                                secretary and accounting services if we evaluate
                                your company to be non-dormant over the course
                                of the financial year.
                              </p>
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div className="mt-2">
                      <RadioButton
                        label={"No"}
                        idFrom={"dormant_company_no"}
                        value={"no"}
                        checked={formik.values.dormant_company === "no"}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                        }}
                        name={"dormant_company"}
                        className={"sm:text-base text-sm"}
                      />
                      <RadioButton
                        label={"Yes"}
                        idFrom={"dormant_company_yes"}
                        value="yes"
                        checked={formik.values.dormant_company === "yes"}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                        }}
                        name={"dormant_company"}
                        className={"sm:text-base text-sm"}
                      />
                    </div>
                    {formik.errors.dormant_company &&
                    formik.touched.dormant_company ? (
                      <InputError errorTitle={formik.errors.dormant_company} />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                  <p className="sm:text-base text-sm sm:mb-0 mb-2">
                    4. Let us know more about you
                  </p>
                  <p className="text-sm">This helps us serve you better</p>
                  <div className="space-y-2 px-3">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      What are your next steps or objectives?
                    </p>
                    {objectivesError && (
                      <InputError errorTitle={objectivesError} />
                    )}
                    <Objectives
                      objectivesData={CompanyData?.objectives}
                      setObjectivesSelectedArray={setObjectivesSelectedArray}
                      setObjectivesError={setObjectivesError}
                    />
                  </div>
                  <div className="space-y-2 px-3">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      How did you find out about us?
                    </p>
                    {CompanyData?.platforms?.map((element) => {
                      return (
                        <RadioButton
                          label={element.title}
                          idFrom={element.title}
                          value={element.id}
                          checked={formik.values.platforms == element.id}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                          }}
                          name={"platforms"}
                          className={"sm:text-base text-sm"}
                        />
                      );
                    })}
                    {formik.values.platforms == 1 && (
                      <TextareaWithLabel
                        idFromName={"about_us_other"}
                        label={"About Us Other"}
                        className={"border"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.about_us_other}
                      />
                    )}
                    {formik.errors.platforms && formik.touched.platforms ? (
                      <InputError errorTitle={formik.errors.platforms} />
                    ) : null}
                  </div>
                </div>
              )}

              {type === "eibiz" ? (
                <>
                  <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      5. Source of funds
                    </p>
                    <p className="sm:text-sm text-xs">
                      Your inputs are an important part of compliance checks.
                    </p>
                    <div className="space-y-2 px-3">
                      <p className="sm:text-base text-sm sm:mb-0 mb-2">
                        Please select all sources for your company’s fund.
                      </p>
                      {companyFundResourcesError && (
                        <InputError errorTitle={companyFundResourcesError} />
                      )}
                      <CompanyFundResources
                        companyFundResourcesData={CompanyData?.fund_resources}
                        companyFundResourcesVales={
                          organisationDetails?.company_funds
                        }
                        company_funds_other={
                          organisationDetails?.company_funds_other
                        }
                        setCompanyFundResourcesSelectedArray={
                          setCompanyFundResourcesSelectedArray
                        }
                        setCompanyFundResourcesError={
                          setCompanyFundResourcesError
                        }
                      />
                      {formik.errors.company_funds &&
                      formik.touched.company_funds ? (
                        <InputError errorTitle={formik.errors.company_funds} />
                      ) : null}
                    </div>
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      Please list up to 3 countries where your business will
                      take place.{" "}
                    </p>
                    {/* Country */}
                    <div>
                      <div
                        className={`${
                          formik.errors.countries && formik.touched.countries
                            ? ` input-error `
                            : ""
                        } relative
              bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactSelect
                          className={"text-black p-0"}
                          id="countries"
                          placeholder="Countries"
                          options={countryArray}
                          isLoading={countryLoading}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          value={formik.values.countries}
                          isMulti
                          onChange={(e) => {
                            formik.setFieldValue("countries", e);
                            formik.setFieldValue("countryId", e?.id);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: `1px solid ${
                                formik.errors.countries &&
                                formik.touched.countries
                                  ? "red"
                                  : "#0000003B"
                              }`,
                              borderRadius: "0.25rem",
                              padding: "6px 4px",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0000003B",
                              },
                            }),
                          }}
                        />
                        <label
                          htmlFor="countries"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Countries
                        </label>
                      </div>
                      {formik.errors.countries && formik.touched.countries ? (
                        <InputError errorTitle={formik.errors.countries} />
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <>{null}</>
              )}

              {type === "eibiz" ? (
                <>
                  <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
                    <p className="sm:text-base text-sm sm:mb-0 mb-2">
                      6. More about you
                    </p>
                    <p className="text-sm">
                      This will helps us serve you better
                    </p>
                    <div className="space-y-2 px-3">
                      <p className="sm:text-base text-sm sm:mb-0 mb-2">
                        What are the next steps/objectives for you
                      </p>

                      {objectivesError && (
                        <InputError errorTitle={objectivesError} />
                      )}
                      <Objectives
                        objectivesData={CompanyData?.objectives}
                        setObjectivesSelectedArray={setObjectivesSelectedArray}
                        setObjectivesError={setObjectivesError}
                        objectivesVales={organisationDetails?.objectives}
                        objectives_other={organisationDetails?.objectives_other}
                      />
                    </div>
                    <div className="space-y-2 px-3">
                      <p className="sm:text-base text-sm sm:mb-0 mb-2">
                        How did you find out about us?
                      </p>
                      {CompanyData?.platforms?.map((element) => {
                        return (
                          <RadioButton
                            label={element.title}
                            idFrom={element.title}
                            value={element.id}
                            checked={formik.values.platforms == element.id}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            name={"platforms"}
                            key={element.id}
                            className={"sm:text-base text-sm"}
                          />
                        );
                      })}
                      {formik.values.platforms == 1 && (
                        <TextareaWithLabel
                          idFromName={"about_us_other"}
                          label={"About Us Other"}
                          className={"border"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.about_us_other}
                        />
                      )}
                      {formik.errors.platforms && formik.touched.platforms ? (
                        <InputError errorTitle={formik.errors.platforms} />
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <>{null}</>
              )}

              <div className="flex justify-end gap-4 pt-3">
                <button
                  onClick={() => navigate("/")}
                  type="button"
                  className="btn-outline flex items-center space-x-3 uppercase"
                >
                  <BackArrow className="fill-theme" fillOpacity={1} />
                  <span>Back</span>
                </button>
                <button
                  type="submit"
                  className="btn-theme flex items-center space-x-3 uppercase"
                >
                  {Loading ? <ButtonLoader /> : <span>save & proceed on</span>}
                  <ArrowForward />
                </button>
              </div>
            </>
          </form>
        </div>
      )}
    </>
  );
};

export default CompanyInfo;
