import React from "react";
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; // date picker
import "react-lazy-load-image-component/src/effects/blur.css"; // eact-lazy-load-image-component
import "react-toastify/dist/ReactToastify.css"; //toast.css
import "swiper/css"; // swiper
import "./assets/css/base.css"; // css
import usePushNotifications from "./commons/usePushNotifications";

const App = () => {
  usePushNotifications();
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
