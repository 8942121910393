import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./auth/slice";
import addressSlice from "./address/slice";
import companiesSlice from "./companies/slice";
import organisationSlice from "./organisation/slice";
import ticketSlice from "./ticket/slice";
import notificationsSlice from "./notifications/slice";

const rootReducer = {
  authData: authSlice,
  addressData: addressSlice,
  companiesData: companiesSlice,
  organisationData: organisationSlice,
  ticketData: ticketSlice,
  notificationsData: notificationsSlice,
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export default store;
