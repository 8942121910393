import React from "react";
import { billingImg } from "../../assets/image";
import {
  MasterCardIcon,
  SubscriptionIcon,
  HandCreditCard,
  HouseSearch,
} from "../../assets/svg/AllSvg";
import MySubscription from "./MySubscription";
import { useNavigate } from "react-router-dom";

const BillingAndSubscriptions = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-5">
      {/* Page Heading */}
      <div className="flex items-center gap-4 border-b-2 pb-5">
        <div className="w-40">
          <img src={billingImg} alt="billingImg" className="w-full" />
        </div>
        <div>
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond mb-2">
            My Billing and Subscriptions
          </h1>
          <p className="text-sm">
            Manage your subscriptions and your transaction
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5">
        {/* My Subscription */}
        <div className="card bg-light_secondary_shades flex gap-4">
          <div className="max-w-14 w-full">
            <SubscriptionIcon className="w-full" />
          </div>
          <div className="space-y-2 w-full">
            <p className="sm:text-xl text-base font-ebGaramond mb-2">
              My Subscription
            </p>
            <div>
              <p className="text-xs text-light_grey">Subscription Type</p>
              <p className="text-sm">Corporate Secretary & Accounting </p>
            </div>
            <div>
              <p className="text-xs text-light_grey">Expired on </p>
              <p className="text-sm">20/03/2024 </p>
            </div>
            <div className="flex justify-end">
              <button className="text-theme border-b border-theme uppercase text-sm">
                View more
              </button>
            </div>
          </div>
        </div>
        {/* My Payment Method */}
        <div
          className="card bg-primary_shades_light cursor-pointer flex gap-4"
          onClick={() => navigate("/payment-method")}
        >
          <div className="max-w-14 w-full">
            <HandCreditCard className="w-full" />
          </div>
          <div className="space-y-2 w-full">
            <p className="sm:text-xl text-base font-ebGaramond mb-2">
              My Payment Method
            </p>
            <div>
              <p className="text-xs text-light_grey">Payment method</p>
              <p className="text-sm">Credit Card</p>
            </div>
            <div className="flex items-center space-x-2">
              <MasterCardIcon />
              <div>
                <p className="text-xs text-light_grey">Expired on </p>
                <p className="text-sm">**** **** **** 1234</p>
              </div>
            </div>
            <div className="flex justify-end">
              <button className="text-theme border-b border-theme uppercase text-sm">
                UPDATE
              </button>
            </div>
          </div>
        </div>
        {/* Billing information */}
        <div className="card bg-primary_shades_light flex gap-4">
          <div className="max-w-14 w-full">
            <HouseSearch className="w-full" />
          </div>
          <div className="space-y-2 flex flex-col justify-between w-full">
            <div className="space-y-2">
              <p className="sm:text-xl text-base font-ebGaramond mb-2">
                Billing information
              </p>
              <div>
                <p className="text-xs text-light_grey">
                  Name 1 Blk 123 Walk the Lane #01-11 Singapore, 123456
                  Singapore
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <button className="text-theme border-b border-theme uppercase text-sm">
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* My Subscription */}
      <MySubscription />
    </div>
  );
};

export default BillingAndSubscriptions;
